import React from 'react';

import TeachingPointModal from 'NewEditDoc/TeachingPointModal/component';

import TableDownArrowImage from "images/table-down_arrow.png";
import TableUpArrowImage from "images/table-up_arrow.png";
import dropdownArrow from 'images/dropdown_arrow.svg';
import analytics from '../../../../../application/analytics';
import CustomMultiSelect from '../CustomMultiSelect';

class StudentResultsBonusDetailsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      filteredData: this.props.rows,
      sortCol: 'status',
      sortDir: 'asc',
      accuracy: '',
      system: [],
      clinical_focus: [],
      clinical_learning_calibration: [],
      certainty: [],
    };
  }

  sortDeliveries = (sortCol, sortDir) => {
    const data = this.state.filteredData;
    data.sort((a, b) => {
      let fa, fb;
      if (sortCol === 'todo_status') {
        fa = a.todo_action_element.includes("Not a to-do") ? "Not a to-do" : "Assigned";
        fb = b.todo_action_element.includes("Not a to-do") ? "Not a to-do" : "Assigned";
        if (fa === "Not a to-do" && fb !== "Not a to-do") return sortDir === 'asc' ? -1 : 1;
        if (fa !== "Not a to-do" && fb === "Not a to-do") return sortDir === 'asc' ? 1 : -1;
      } else {
        fa = a[sortCol].toLowerCase();
        fb = b[sortCol].toLowerCase();
      }
      if (fa < fb) {
        return sortDir === 'asc' ? -1 : 1;
      }
      if (fa > fb) {
        return sortDir === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return data;
  }

  sortImage = (sortCol) => {
    return this.state.sortCol === sortCol ?
      (this.state.sortDir === 'asc' ? TableDownArrowImage : TableUpArrowImage) : TableDownArrowImage;
  }

  getTeachingPointModal(row) {
    if (row.teaching_point_url_name !== 'N/A') {
      return <TeachingPointModal
        title={row.teaching_point_url_name}
        docType="ReusableObject"
        documentId={row.teaching_point_id}
        versionId={row.teaching_point_publish_variant_id}
        currentUser={this.props.user}
        isAdmin={this.props.admin}
        currentRoleName="Student"
        permissions={{}}
        itemResponseId={row.item_response_id}
      />
    }
  }

  trackFilterUsage(filter) {
    analytics.track("use_filters_on_tab_3", { page: window.location.href, source: 'Calibrate Reports', filters: filter });
  }

  applyFilters() {
    const { system, accuracy, clinical_focus, certainty, clinical_learning_calibration, search } = this.state;

    const filteredData = this.props.rows.filter((item) => {
      const matchesSystem = system.length === 0 || system.some(sys => item.system?.includes(sys));
      const matchesAccuracy = accuracy === '' || item.accuracy === accuracy;
      const matchesClinicalFocus = clinical_focus.length === 0 || clinical_focus.some(cf => item.clinical_focus?.includes(cf));
      const matchesCertainty = certainty.length === 0 || certainty.some(c => item.certainty?.includes(c));
      const matchesClinicalLearningCalibration = clinical_learning_calibration.length === 0 || clinical_learning_calibration.some(clc => item.clinical_learning_calibration?.includes(clc));
      const matchesSearch = (item.learning_objective || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.system || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.case_name || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.clinical_focus || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.certainty || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.clinical_learning_calibration || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.clc_label || '').toLowerCase().includes(search.toLowerCase()) ||
        (item.teaching_point_url_name || '').toLowerCase().includes(search.toLowerCase());

      return matchesSystem && matchesAccuracy && matchesClinicalFocus && matchesCertainty && matchesClinicalLearningCalibration && matchesSearch;
    });

    this.setState({ filteredData });
  }

  handleFilterChange(filterName, values) {
    this.setState({ [filterName]: values }, this.applyFilters);
    this.trackFilterUsage({ [filterName]: values });
  }

  render() {
    return (
      <div className="detailsSection">
        <div className="col-md-12" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="filtersRow" style={{ borderBottom: '0px', height: 'auto' }}>
            <div className="filtersContainer">
              <div className='col-md-10'>

                <CustomMultiSelect
                  options={this.props.system_options}
                  placeholder="Systems"
                  selectedOptions={this.state.system}
                  onChange={(selectedOptions) => this.handleFilterChange('system', selectedOptions)}
                  dropdownArrow= {dropdownArrow}
                />

                <CustomMultiSelect
                  options={this.props.clinical_focus_options}
                  placeholder="Clinical Focus"
                  selectedOptions={this.state.clinical_focus}
                  onChange={(selectedOptions) => this.handleFilterChange('clinical_focus', selectedOptions)}
                  dropdownArrow= {dropdownArrow}
                />

                <select
                  className='form-control filterSelect'
                  value={this.state.accuracy || ''}
                  onChange={(e) => {
                    this.handleFilterChange('accuracy', e.target.value);
                  }}
                >
                  <option value="" disabled>Accuracy</option>
                  {this.props.accuracy_options.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <CustomMultiSelect
                  options={this.props.certainty_options}
                  placeholder="Certainty"
                  selectedOptions={this.state.certainty}
                  onChange={(selectedOptions) => this.handleFilterChange('certainty', selectedOptions)}
                  dropdownArrow= {dropdownArrow}
                />

                <CustomMultiSelect
                  options={this.props.clinical_learning_calibration_options.map(option => (option.charAt(0).toUpperCase() + option.slice(1)))}
                  placeholder="Clinical Learning Calibration"
                  selectedOptions={this.state.clinical_learning_calibration}
                  onChange={(selectedOptions) => this.handleFilterChange('clinical_learning_calibration', selectedOptions)}
                  dropdownArrow= {dropdownArrow}
                />
              </div>

              <div className="form-group searchField">
                <input
                  type="text"
                  name="search"
                  placeholder='Search'
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ search: e.target.value }, this.applyFilters);
                  }}
                  aria-label="Search"
                />
              </div>
            </div>
          </div>
          <div className='selectedFilters'>
            {this.state.accuracy && <p onClick={() => this.handleFilterChange('accuracy', '')} className='filterSelectedVal'>{this.state.accuracy}&nbsp;&nbsp;&nbsp; X</p>}
            {this.state.certainty.length > 0 && this.state.certainty.map((selectedCertainty, index) => (
              <p key={index} onClick={() => {
                const updatedCertainties = this.state.certainty.filter(c => c !== selectedCertainty);
                this.handleFilterChange('certainty', updatedCertainties);
              }} className='filterSelectedVal'>{selectedCertainty}&nbsp;&nbsp;&nbsp; X</p>
            ))}
            {this.state.system.length > 0 && this.state.system.map((selectedSystem, index) => (
              <p key={index} onClick={() => {
                const updatedSystems = this.state.system.filter(sys => sys !== selectedSystem);
                this.handleFilterChange('system', updatedSystems);
              }} className='filterSelectedVal'>{selectedSystem}&nbsp;&nbsp;&nbsp; X</p>
            ))}
            {this.state.clinical_focus.length > 0 && this.state.clinical_focus.map((selectedFocus, index) => (
              <p key={index} onClick={() => {
                const updatedFocuses = this.state.clinical_focus.filter(focus => focus !== selectedFocus);
                this.handleFilterChange('clinical_focus', updatedFocuses);
              }} className='filterSelectedVal'>{selectedFocus}&nbsp;&nbsp;&nbsp; X</p>
            ))}
            {this.state.clinical_learning_calibration.length > 0 && this.state.clinical_learning_calibration.map((selectedCalibration, index) => (
              <p key={index} onClick={() => {
                const updatedCalibrations = this.state.clinical_learning_calibration.filter(calibration => calibration !== selectedCalibration);
                this.handleFilterChange('clinical_learning_calibration', updatedCalibrations);
              }} className='filterSelectedVal'>{selectedCalibration}&nbsp;&nbsp;&nbsp; X</p>
            ))}
          </div>
        </div>

        <table className="detailsTable">
          <thead>
            <tr>
              <th className="bottomSectionGraphLegendText"> {this.props.show_as_educator_report ? 'RESULTS' : 'CLINICAL LEARNING CALIBRATION' }</th>
              <th className="bottomSectionGraphLegendText">{this.props.show_as_educator_report ? 'LEARNING OBJECTIVE' : 'QUESTION CONTENT / LEARNING OBJECTIVE' }</th>
              <th className="bottomSectionGraphLegendText"> {this.props.show_as_educator_report ? 'TEACHING POINT' : 'ANSWER EXPLANATION / TEACHING POINT' }</th>
              <th
                className='bottomSectionGraphLegendText tableSortCol'
                onClick={() => this.setState({
                  sortCol: 'clinical_focus',
                  sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                  filteredData: this.sortDeliveries('clinical_focus', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                })
                }
              >
                <div className='sortable'>Clinical Focus <img src={this.sortImage('clinical_focus')} alt="case name" className="icon-case_name" /></div>
              </th>
              <th
                className='bottomSectionGraphLegendText tableSortCol'
                onClick={() => this.setState({
                  sortCol: 'system',
                  sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                  filteredData: this.sortDeliveries('system', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                })
                }
              >
                <div className='sortable'>System <img src={this.sortImage('system')} alt="case name" className="icon-case_name" /></div>
              </th>
              <th className="bottomSectionGraphLegendText">Aquifer Case</th>
              {this.props.show_as_educator_report ? (
                <th
                  className='bottomSectionGraphLegendText tableSortCol'
                  onClick={() => this.setState({
                    sortCol: 'todo_status',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('todo_status', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                  })
                  }
                >
                  <div className='sortable'> TO-DO STATUS <img src={this.sortImage('todo_status')} alt="case name" className="icon-case_name" /></div>
                </th>
              ) : (
                <th className="bottomSectionGraphLegendText"> ADD TO-DO </th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.state.filteredData.map((row) => {
              return <tr>
                {this.props.show_as_educator_report ? (
                  <td>
                    <div className={`items ${row.clinical_learning_calibration.toLowerCase()}`}>
                      <div className="item-content">
                        <div className="content" style={{ paddingLeft: '15px' }}>
                          <p className="clc-level"><strong>{row.clinical_learning_calibration ?? 'N/A'}</strong></p>
                          <p className="clc-level">{row.clc_label ?? 'N/A'}</p>
                          <p className="clc-level">{row.certainty ?? 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                ) : (
                  <td>{row.clinical_learning_calibration ?? 'N/A'}</td>
                )}
                <td>{row.learning_objective ?? 'N/A'}</td>
                <td>
                  {this.getTeachingPointModal(row)}
                </td>
                <td><p className="metadata-box clinical-focus">{row.clinical_focus ?? 'N/A'}</p></td>
                <td><p className="metadata-box system" style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>{row.system ?? 'N/A'}</p></td>
                <td>
                  <a className={'caseNameLink'} href={row.case_path} target="_blank" onClick={() => analytics.track("click_case_link", { page: window.location.href, source: 'Calibrate Reports', item_response_id: row.item_response_id })}>{row.case_name}</a><br /><br />
                  <div className={`progress-circle ${row.case_status.circle}`}>
                    {row.case_status.circle == 'yellow' ? `${row.case_progress}%` : null}
                    <span className="tooltip-box xsmall">
                      {row.case_status.progress_circle_tip}
                    </span>
                  </div>
                </td>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: row.todo_action_element }} />
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StudentResultsBonusDetailsTable;
