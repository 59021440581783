import React from "react";
import ReactDOM from "react-dom";
import GenSortTable from "Gen/SortTable/component";

import TableDownArrowImage from "images/down_arrowhead.svg";
import TableFilterImage from "images/todo-filter.png";
import AssessmentDashboardModal from "./assessment_dashboard_modal";

class DeliveriesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredData: this.props.rows,
      sortCol: "status",
      sortDir: "asc",
      selectedDeliveries: [],
      selectAllActive: false,
      isModalOpen: false,
      selectedRowData: [],

      showFilters: {
        isOpendiscipline: false,
        isOpenStatus: false,
        isOpenPurpose: false,
        isOpenDefaultForm: false,
        isOpenVersions: false,
        isOpenProgram: false,
        isOpenTypesOfStudent: false,
        isOpenAvailableDates: false,
        isOpenNickname: false,
      },

      exam_title: Array.from(
        new Set(
          this.props.rows.map((item) =>
            item.exam_title.replace(/\s+\d{4}$/, "")
          )
        )
      ),
      status: Array.from(new Set(this.props.rows.map((item) => item.status))),
      purpose: Array.from(new Set(this.props.rows.map((item) => item.purpose))),
      group_name: Array.from(
        new Set(this.props.rows.map((item) => item.group_name))
      ),
      nickname: Array.from(
        new Set(this.props.rows.map((item) => item.nickname))
      ),
      versions: Array.from(
        new Set(this.props.rows.map((item) => item.versions))
      ),
      availability_range: Array.from(
        new Set(this.props.rows.map((item) => item.availability_range))
      ),
      type_of_student: Array.from(
        new Set(this.props.rows.map((item) => item.type_of_student))
      ),
      default_form: Array.from(
        new Set(this.props.rows.map((item) => item.default_form))
      ),
      date_range: Array.from(
        new Set(this.props.rows.map((item) => item.date_range))
      ),

      disciplineQuery: "",
      statusQuery: "",
      purposeQuery: "",
      versionsQuery: "",
      programQuery: "",
      typeOfStudentQuery: "",
      defaultFormQuery: "",
      availiblityDatesQuery: "",
      nicknameQuery: "",

      appliedfilters: {
        status: [],
        exam_title: [],
        group_name: [],
        availability_range: [],
        purpose: [],
        default_form: [],
        type_of_student: [],
        versions: [],
        nickname: [],
      },
    };
  }

  handleClick = (appliedFilter) => {
    const filteredData = this.props.rows.filter((item) => {
      return Object.keys(appliedFilter).every((key) => {
        if (appliedFilter[key].length === 0) {
          return true;
        }
        const itemValue =
          key === "exam_title" ? item[key].replace(/\s+\d{4}$/, "") : item[key];
        return appliedFilter[key].includes(itemValue);
      });
    });
    this.setState({
      filteredData: filteredData,
      appliedfilters: appliedFilter,
      selectedDeliveries: this.state.selectAllActive
        ? filteredData
            .filter((row) => row.student_completed_count !== 0)
            .map((row) => row.id)
        : [],
    });
  };

  handleChange = (key, queryName, e) => {
    const value = e.target.value.toLowerCase();

    let filteredSuggestions = this.props.rows.map((item) => {
      let itemValue = item[key];
      if (queryName === "disciplineQuery") {
        itemValue = itemValue.replace(/\s+\d{4}$/, "");
      }
      return itemValue;
    });

    filteredSuggestions = Array.from(new Set(filteredSuggestions)).filter(
      (item) => item.toLowerCase().includes(value)
    );

    this.setState({
      [queryName]: value,
      [key]: filteredSuggestions,
    });

    if (value === "") {
      this.handleClick({ ...this.state.appliedfilters, [key]: [] });
      this.handleCloseFilter(key);
    } else {
      this.handleClick({
        ...this.state.appliedfilters
      });
    }
  };
  
  closeFilterOptions(event) {
    let closeFilters = this.state.showFilters;
    const isClickInside = event.target.closest(".searchDeliveryWrapper");

    if (!isClickInside) {
      Object.keys(closeFilters).forEach((key) => {
        closeFilters[key] = false;
      });
    }
    this.setState({ showFilters: closeFilters });
  }

  handleCloseShowFilter = (filterKey) => {
    let closeFilters = this.state.showFilters;
    Object.keys(closeFilters).forEach((key) => {
      if (key !== filterKey) {
        closeFilters[key] = false;
      }
    });

    this.setState({ showFilters: closeFilters });
  };

  countAppliedFilters(filters) {
    return Object.values(filters)
      .filter(Array.isArray)
      .reduce((acc, arr) => acc + arr.length, 0);
  }

  handleCloseFilter = (filterKey, filterValue) => {
    let filtersObject = { ...this.state.appliedfilters };

    let valueOfFilterKey = filtersObject[filterKey].filter(
      (value) => value !== filterValue
    );
    filtersObject[filterKey] = valueOfFilterKey;

    let filterQuery = `${this.getFilterKeys(filterKey).replaceAll(
      " ",
      ""
    )}Query`;
    let key = filterQuery.charAt(0).toLowerCase() + filterQuery.slice(1);

    this.setState({ appliedfilters: filtersObject, [key]: "" });
    this.handleClick(filtersObject);
  };

  getFilterKeys = (appliedFilter) => {
    let filterkeys = {
      status: "Status",
      exam_title: "Discipline",
      group_name: "Program",
      availability_range: "Availiblity Dates",
      purpose: "Purpose",
      default_form: "Default Form",
      type_of_student: "Type Of Student",
      versions: "Versions",
      nickname: "Nickname",
    };
    return filterkeys[appliedFilter];
  };

  handleSelectAll = () => {
    const allRowIds = this.state.filteredData
      .filter((row) => row.student_completed_count !== 0)
      .map((row) => row.id);
    this.setState({ selectedDeliveries: allRowIds, selectAllActive: true });
  };

  handleDeselectAll = () => {
    this.setState({ selectedDeliveries: [], selectAllActive: false });
  };

  handleSelection = () => {
    if (this.state.selectedDeliveries.length > 0) {
      this.handleDeselectAll();
    } else {
      this.handleSelectAll();
    }
  };

  updateFilterOptions = (item, filterValue, filterKey) => {
    let updatedItem = item.filter(title => title !== filterValue);
    this.setState({[filterKey]: updatedItem});
  }

  addSelectedFilter = (value, key) => {
    let options = this.state[key];
    this.setState({[key]: [...options, value]});
  }

  handleOpenModal = (row) => {
    this.setState({ isModalOpen: true, selectedRowData: row });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { isModalOpen } = this.state;
    return (
      <div onClick={(event) => this.closeFilterOptions(event)}>
        <div className="calibrateSectionSubHeadingRow">
          <p className="calibrateSectionHeading calibrateSectionSubHeading selectedDeliveryFilter">
            Active & Completed Assessments
          </p>
          <a
            href={`${this.props.index_path}?${this.state.selectedDeliveries.map(
              (d) => `&ids[]=${d}`
            )}`}
            className={`actionBtn ml-1 float-right ${
              this.state.selectedDeliveries.length == 0 ? "disabled" : ""
            }`}
            style={
              this.state.selectedDeliveries.length == 0
                ? { pointerEvents: "none", position: "relative", top: "30px" }
                : { position: "relative", top: "30px" }
            }
          >
            View Selected Results
          </a>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div
            className="searchRow"
            style={{ position: "relative", top: "20px" }}
          >
            <input
              type="text"
              name="search"
              style={{ width: "30%" }}
              placeholder="Search"
              className="form-control"
              onChange={(e) => {
                const searchQuery = e.target.value.toLowerCase();
                const filteredData = this.props.rows.filter((item) => {
                  return (
                    item.exam_title.toLowerCase().indexOf(searchQuery) > -1 ||
                    item.purpose.toLowerCase().indexOf(searchQuery) > -1 ||
                    item.status.toLowerCase().indexOf(searchQuery) > -1 ||
                    item.availability_range.toLowerCase().indexOf(searchQuery) >
                      -1 ||
                    item.default_form.toLowerCase().indexOf(searchQuery) > -1 ||
                    item.group_name.toLowerCase().indexOf(searchQuery) > -1 ||
                    item.nickname.toLowerCase().indexOf(searchQuery) > -1 ||
                    item.type_of_student.toLowerCase().indexOf(searchQuery) >
                      -1 ||
                    item.versions.toLowerCase().indexOf(searchQuery) > -1
                  );
                });

                const selectedDeliveries = this.state.selectAllActive
                  ? filteredData
                      .filter((row) => row.student_completed_count !== 0)
                      .map((row) => row.id)
                  : [];

                this.setState({
                  filteredData,
                  selectedDeliveries,
                  search: searchQuery,
                });
              }}
            />
            <div style={{ width: "60%" }}>
              <div
                style={
                  this.countAppliedFilters(this.state.appliedfilters) == 0
                    ? { textAlign: "center", paddingBottom: "19px" }
                    : {
                        textAlign: "center",
                        position: "relative",
                        top: "-10px",
                      }
                }
              >
                {this.countAppliedFilters(this.state.appliedfilters) !== 0
                  ? `${this.countAppliedFilters(
                      this.state.appliedfilters
                    )} filters are Selected`
                  : "No filters selected"}
              </div>
              <div style={{ margin: "10px 10px 0", overflowText: "nowrap" }}>
                {Object.keys(this.state.appliedfilters).map((key, index) => {
                  return this.state.appliedfilters[key].map(
                    (value, valueIndex) => (
                      <span
                        onClick={() => {
                          this.handleCloseFilter(key, value)
                          this.addSelectedFilter(value, key);
                        }}
                        style={{
                          padding: "5px",
                          margin: "2px 4px",
                          backgroundColor: "#E8ECEE",
                          display: "inline-flex",
                          position: "relative",
                          bottom: "19px",
                          cursor: "pointer",
                        }}
                        key={`${key}-${valueIndex}`}
                      >
                        {value}&nbsp;&nbsp;&nbsp;&nbsp;x
                      </span>
                    )
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <button
              style={{ whiteSpace: "nowrap", marginTop: "20px", top: "19px" }}
              className="secondary-button"
              onClick={this.handleSelection}
            >
              {this.state.selectedDeliveries.length > 0
                ? "DESELECT ALL"
                : "SELECT ALL SHOWN"}
            </button>
          </div>
        </div>
        <table
          className={`deliveriesTable ${this.props.isAdmin ? "admin" : null}`}
        >
          <thead>
            <tr>
              <th className="tableSortCol">
                <div
                  style={{ position: "relative" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleCloseShowFilter("isOpenStatus");
                    this.setState({
                      showFilters: {
                        ...this.state.showFilters,
                        isOpenStatus: !this.state.showFilters.isOpenStatus,
                      },
                    });
                  }}
                >
                  Status{" "}
                  <img
                    src={
                      this.state.appliedfilters.status.length > 0
                        ? TableFilterImage
                        : TableDownArrowImage
                    }
                    alt="case name"
                    className="icon-case_name"
                  />{" "}
                </div>
                <div className="searchRow searchDeliveries">
                  {this.state.showFilters.isOpenStatus == true ? (
                    <div className="searchDeliveryWrapper">
                      <input
                        type="text"
                        name="statusQuery"
                        value={this.state.statusQuery}
                        placeholder="Search"
                        className="form-control"
                        onChange={(event) =>
                          this.handleChange("status", "statusQuery", event)
                        }
                        onClick={(event) => event.stopPropagation()}
                      />

                      {this.state.status.length > 0 ? (
                        <ul>
                          {this.state.status.map((statusItem, index) => (
                            <li
                              onClick={() => {
                                this.updateFilterOptions(this.state.status, statusItem, 'status');
                                this.setState({
                                  showFilters: {
                                    ...this.state.showFilters,
                                    isOpenStatus: false,
                                  },
                                  statusQuery: statusItem,
                                });
                                if (
                                  !this.state.appliedfilters.status.includes(
                                    statusItem
                                  )
                                ) {
                                  this.handleClick({
                                    ...this.state.appliedfilters,
                                    status: [
                                      ...this.state.appliedfilters.status,
                                      statusItem,
                                    ],
                                  });
                                }
                              }}
                              key={index}
                            >
                              {statusItem}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </th>
              <th className="tableSortCol">
                <div
                  style={{ position: "relative" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleCloseShowFilter("isOpendiscipline");
                    this.setState({
                      showFilters: {
                        ...this.state.showFilters,
                        isOpendiscipline:
                          !this.state.showFilters.isOpendiscipline,
                      },
                    });
                  }}
                >
                  Discipline
                  <img
                    src={
                      this.state.appliedfilters.exam_title.length > 0
                        ? TableFilterImage
                        : TableDownArrowImage
                    }
                    alt="case name"
                    className="icon-case_name"
                  />
                </div>
                <div className="searchRow searchDeliveries">
                  {this.state.showFilters.isOpendiscipline == true ? (
                    <div className="searchDeliveryWrapper">
                      <input
                        type="text"
                        name="disciplineQuery"
                        value={this.state.disciplineQuery}
                        placeholder="Search"
                        className="form-control"
                        onChange={(event) =>
                          this.handleChange(
                            "exam_title",
                            "disciplineQuery",
                            event
                          )
                        }
                        onClick={(event) => event.stopPropagation()}
                      />
                      {this.state.exam_title.length > 0 ? (
                        <ul>
                          {this.state.exam_title.map((examTitle, index) => (
                            <li
                              key={index}
                              onClick={() => {                               
                                this.updateFilterOptions(this.state.exam_title, examTitle, 'exam_title');
                                this.setState({
                                  disciplineQuery: examTitle,
                                  showFilters: {
                                    ...this.state.showFilters,
                                    isOpendiscipline:
                                      !this.state.isOpendiscipline,
                                  },
                                });
                                if (
                                  !this.state.appliedfilters.exam_title.includes(
                                    examTitle
                                  )
                                ) {
                                  this.handleClick({
                                    ...this.state.appliedfilters,
                                    exam_title: [
                                      ...this.state.appliedfilters.exam_title,
                                      examTitle,
                                    ],
                                  });
                                }
                              }}
                            >
                              {examTitle}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </th>
              <th>
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleCloseShowFilter("isOpenNickname");
                    this.setState({
                      showFilters: {
                        ...this.state.showFilters,
                        isOpenNickname: !this.state.showFilters.isOpenNickname,
                      },
                    });
                  }}
                >
                  Nickname
                  <img
                    src={
                      this.state.appliedfilters.nickname.length > 0
                        ? TableFilterImage
                        : TableDownArrowImage
                    }
                    alt="case name"
                    className="icon-case_name"
                  />
                </div>
                <div className="searchRow searchDeliveries">
                  {this.state.showFilters.isOpenNickname == true ? (
                    <div className="searchDeliveryWrapper">
                      <input
                        type="text"
                        name="nicknameQuery"
                        value={this.state.nicknameQuery}
                        placeholder="Search"
                        className="form-control"
                        onChange={(event) =>
                          this.handleChange("nickname", "nicknameQuery", event)
                        }
                        onClick={(event) => event.stopPropagation()}
                      />
                      {this.state.nickname.length > 0 ? (
                        <ul>
                          {this.state?.nickname?.map((nickname, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                this.updateFilterOptions(this.state.nickname, nickname, 'nickname');
                                this.setState({
                                  nicknameQuery: nickname,
                                  showFilters: {
                                    ...this.state.showFilters,
                                    isOpenNickname: !this.state.isOpenNickname,
                                  },
                                });
                                if (
                                  !this.state.appliedfilters.nickname.includes(
                                    nickname
                                  )
                                ) {
                                  this.handleClick({
                                    ...this.state.appliedfilters,
                                    nickname: [
                                      ...this.state.appliedfilters.nickname,
                                      nickname,
                                    ],
                                  });
                                }
                              }}
                            >
                              {nickname}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </th>
              {this.props.isAdmin ? (
                <th>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleCloseShowFilter("isOpenProgram");
                      this.setState({
                        showFilters: {
                          ...this.state.showFilters,
                          isOpenProgram: !this.state.showFilters.isOpenProgram,
                        },
                      });
                    }}
                  >
                    Program
                    <img
                      src={
                        this.state.appliedfilters.group_name.length > 0
                          ? TableFilterImage
                          : TableDownArrowImage
                      }
                      alt="case name"
                      className="icon-case_name"
                    />
                  </div>
                  <div className="searchRow searchDeliveries">
                    {this.state.showFilters.isOpenProgram == true ? (
                      <div className="searchDeliveryWrapper">
                        <input
                          type="text"
                          name="programQuery"
                          value={this.state.programQuery}
                          placeholder="Search"
                          className="form-control"
                          onChange={(event) =>
                            this.handleChange(
                              "group_name",
                              "programQuery",
                              event
                            )
                          }
                          onClick={(event) => event.stopPropagation()}
                        />
                        {this.state.group_name.length > 0 ? (
                          <ul>
                            {this.state?.group_name?.map((program, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  this.updateFilterOptions(this.state.group_name, program, 'group_name');
                                  this.setState({
                                    programQuery: program,
                                    showFilters: {
                                      ...this.state.showFilters,
                                      isOpenProgram: !this.state.isOpenProgram,
                                    },
                                  });
                                  if (
                                    !this.state.appliedfilters.group_name.includes(
                                      program
                                    )
                                  ) {
                                    this.handleClick({
                                      ...this.state.appliedfilters,
                                      group_name: [
                                        ...this.state.appliedfilters.group_name,
                                        program,
                                      ],
                                    });
                                  }
                                }}
                              >
                                {program}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              ) : null}
              <th className="tableSortCol">
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleCloseShowFilter("isOpenAvailableDates");
                    this.setState({
                      showFilters: {
                        ...this.state.showFilters,
                        isOpenAvailableDates:
                          !this.state.showFilters.isOpenAvailableDates,
                      },
                    });
                  }}
                >
                  Availability Dates{" "}
                  <img
                    src={
                      this.state.appliedfilters.availability_range.length > 0
                        ? TableFilterImage
                        : TableDownArrowImage
                    }
                    alt="case name"
                    className="icon-case_name"
                  />
                </div>
                <div className="searchRow searchDeliveries">
                  {this.state.showFilters.isOpenAvailableDates == true ? (
                    <div className="searchDeliveryWrapper">
                      <input
                        type="text"
                        name="availiblityDatesQuery"
                        value={this.state.availiblityDatesQuery}
                        placeholder="Search"
                        className="form-control"
                        onChange={(event) =>
                          this.handleChange(
                            "availability_range",
                            "availiblityDatesQuery",
                            event
                          )
                        }
                        onClick={(event) => event.stopPropagation()}
                      />
                      {this.state.availability_range.length > 0 ? (
                        <ul>
                          {this.state.availability_range.map(
                            (dateRange, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  this.updateFilterOptions(this.state.availability_range, dateRange, 'availability_range');
                                  this.setState({
                                    availiblityDatesQuery: dateRange,
                                    showFilters: {
                                      ...this.state.showFilters,
                                      isOpenAvailableDates:
                                        !this.state.isOpenAvailableDates,
                                    },
                                  });
                                  if (
                                    !this.state.appliedfilters.availability_range.includes(
                                      dateRange
                                    )
                                  ) {
                                    this.handleClick({
                                      ...this.state.appliedfilters,
                                      availability_range: [
                                        ...this.state.appliedfilters
                                          .availability_range,
                                        dateRange,
                                      ],
                                    });
                                  }
                                }}
                              >
                                {dateRange}
                              </li>
                            )
                          )}
                        </ul>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </th>
              <th
                className="tableSortCol"
                onClick={() =>
                  this.setState({
                    sortCol: "purpose",
                  })
                }
              >
                <div
                  style={{ position: "relative" }}
                  className="deliveryThCtr"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleCloseShowFilter("isOpenPurpose");
                    this.setState({
                      showFilters: {
                        ...this.state.showFilters,
                        isOpenPurpose: !this.state.showFilters.isOpenPurpose,
                      },
                    });
                  }}
                >
                  Early/Late{" "}
                  <img
                    src={
                      this.state.appliedfilters.purpose.length > 0
                        ? TableFilterImage
                        : TableDownArrowImage
                    }
                    alt="case name"
                    className="icon-case_name"
                  />
                </div>
                <div className="searchRow searchDeliveries">
                  {this.state.showFilters.isOpenPurpose == true ? (
                    <div className="searchDeliveryWrapper">
                      <input
                        type="text"
                        name="purposeQuery"
                        value={this.state.purposeQuery}
                        placeholder="Search"
                        className="form-control"
                        onChange={(event) =>
                          this.handleChange("purpose", "purposeQuery", event)
                        }
                        onClick={(e) => e.stopPropagation()}
                      />
                      {this.state.purpose.length > 0 ? (
                        <ul>
                          {this.state.purpose.map((deliveryPurpose, index) => (
                            <li
                              onClick={() => {
                                this.updateFilterOptions(this.state.purpose, deliveryPurpose, 'purpose');
                                this.setState({
                                  showFilters: {
                                    ...this.state.showFilters,
                                    isOpenPurpose: false,
                                  },
                                  purposeQuery: deliveryPurpose,
                                });
                                if (
                                  !this.state.appliedfilters.purpose.includes(
                                    deliveryPurpose
                                  )
                                ) {
                                  this.handleClick({
                                    ...this.state.appliedfilters,
                                    purpose: [
                                      ...this.state.appliedfilters.purpose,
                                      deliveryPurpose,
                                    ],
                                  });
                                }
                              }}
                              key={index}
                            >
                              {deliveryPurpose}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </th>
              {this.props.isAdmin ? (
                <th>
                  <div
                    className="deliveryThCtr"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleCloseShowFilter("isOpenDefaultForm");
                      this.setState({
                        showFilters: {
                          ...this.state.showFilters,
                          isOpenDefaultForm:
                            !this.state.showFilters.isOpenDefaultForm,
                        },
                      });
                    }}
                  >
                    Default Form
                    <img
                      src={
                        this.state.appliedfilters.default_form.length > 0
                          ? TableFilterImage
                          : TableDownArrowImage
                      }
                      alt="case name"
                      className="icon-case_name"
                    />
                  </div>
                  <div className="searchRow searchDeliveries">
                    {this.state.showFilters.isOpenDefaultForm == true ? (
                      <div className="searchDeliveryWrapper">
                        <input
                          type="text"
                          name="defaultFormQuery"
                          value={this.state.defaultFormQuery}
                          placeholder="Search"
                          className="form-control"
                          onChange={(event) =>
                            this.handleChange(
                              "default_form",
                              "defaultFormQuery",
                              event
                            )
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                        {this.state.default_form.length > 0 ? (
                          <ul>
                            {this.state.default_form.map((form, index) => (
                              <li
                                onClick={() => {
                                  this.updateFilterOptions(this.state.default_form, form, 'default_form');
                                  this.setState({
                                    showFilters: {
                                      ...this.state.showFilters,
                                      isOpenDefaultForm: false,
                                    },
                                    defaultFormQuery: form,
                                  });
                                  if (
                                    !this.state.appliedfilters.default_form.includes(
                                      form
                                    )
                                  ) {
                                    this.handleClick({
                                      ...this.state.appliedfilters,
                                      default_form: [
                                        ...this.state.appliedfilters
                                          .default_form,
                                        form,
                                      ],
                                    });
                                  }
                                }}
                                key={index}
                              >
                                {form}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </th>
              ) : null}
              {this.props.isAdmin ? (
                <th>
                  <div
                    className="deliveryThCtr"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleCloseShowFilter("isOpenVersions");
                      this.setState({
                        showFilters: {
                          ...this.state.showFilters,
                          isOpenVersions:
                            !this.state.showFilters.isOpenVersions,
                        },
                      });
                    }}
                  >
                    Versions
                    <img
                      src={
                        this.state.appliedfilters.versions.length > 0
                          ? TableFilterImage
                          : TableDownArrowImage
                      }
                      alt="case name"
                      className="icon-case_name"
                    />
                  </div>
                  <div className="searchRow searchDeliveries">
                    {this.state.showFilters.isOpenVersions == true ? (
                      <div className="searchDeliveryWrapper">
                        <input
                          type="text"
                          name="versionsQuery"
                          value={this.state.versionsQuery}
                          placeholder="Search"
                          className="form-control"
                          onChange={(event) =>
                            this.handleChange(
                              "versions",
                              "versionsQuery",
                              event
                            )
                          }
                          onClick={(event) => event.stopPropagation()}
                        />
                        {this.state.versions.length > 0 ? (
                          <ul>
                            {this.state.versions.map((version, index) => (
                              <li
                                onClick={() => {
                                  this.updateFilterOptions(this.state.versions, version, 'versions');
                                  this.setState({
                                    showFilters: {
                                      ...this.state.showFilters,
                                      isOpenVersions: false,
                                    },
                                    versionsQuery: version,
                                  });
                                  if (
                                    !this.state.appliedfilters.versions.includes(
                                      version
                                    )
                                  ) {
                                    this.handleClick({
                                      ...this.state.appliedfilters,
                                      versions: [
                                        ...this.state.appliedfilters.versions,
                                        version,
                                      ],
                                    });
                                  }
                                }}
                                key={index}
                              >
                                {version}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </th>
              ) : null}

              <th>
                <div># of students registered</div>
              </th>
              <th>
                <div># of students completed</div>
              </th>
              {this.props.isAdmin ? (
                <th>
                  <div
                    className="deliveryThCtr"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleCloseShowFilter("isOpenTypesOfStudent");
                      this.setState({
                        showFilters: {
                          ...this.state.showFilters,
                          isOpenTypesOfStudent:
                            !this.state.showFilters.isOpenTypesOfStudent,
                        },
                      });
                    }}
                  >
                    <span className="text-center">Type of Student</span>
                    <img
                      src={
                        this.state.appliedfilters.type_of_student.length > 0
                          ? TableFilterImage
                          : TableDownArrowImage
                      }
                      alt="case name"
                      className="icon-case_name"
                    />
                  </div>
                  <div className="searchRow searchDeliveries">
                    {this.state.showFilters.isOpenTypesOfStudent == true ? (
                      <div className="searchDeliveryWrapper">
                        <input
                          type="text"
                          name="typeOfStudentQuery"
                          value={this.state.typeOfStudentQuery}
                          placeholder="Search"
                          className="form-control"
                          onChange={(event) =>
                            this.handleChange(
                              "type_of_student",
                              "typeOfStudentQuery",
                              event
                            )
                          }
                          onClick={(event) => event.stopPropagation()}
                        />
                        {this.state.type_of_student.length > 0 ? (
                          <ul>
                            {this.state.type_of_student.map(
                              (studentType, index) => (
                                <li
                                  onClick={() => {
                                    this.updateFilterOptions(this.state.type_of_student, studentType, 'type_of_student');
                                    this.setState({
                                      showFilters: {
                                        ...this.state.showFilters,
                                        isOpenTypesOfStudent: false,
                                      },
                                      typeOfStudentQuery: studentType,
                                    });
                                    if (
                                      !this.state.appliedfilters.type_of_student.includes(
                                        studentType
                                      )
                                    ) {
                                      this.handleClick({
                                        ...this.state.appliedfilters,
                                        type_of_student: [
                                          ...this.state.appliedfilters
                                            .type_of_student,
                                          studentType,
                                        ],
                                      });
                                    }
                                  }}
                                  key={index}
                                >
                                  {studentType}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </th>
              ) : null}
              {this.props.feature_flag_admin_upgrades ?
                <th>
                  <div className="deliveryThCtr">Tools</div>
                </th> : null
              }
              <th style={{ paddingRight: "20px" }}>
                <div className="deliveryThCtr">View results</div>
              </th>
              <th
                style={{ borderLeft: "#444444 1px solid", paddingLeft: "20px" }}
              >
                <div style={{ position: "relative" }}>
                  <div className="multiCohortHeader">View multiple results</div>
                  <div className="deliveriesSelectInfo">
                    <span className="tooltip-box small before">
                      Select multiple
                      <br />
                      assessments
                      <br />
                      to view results
                      <br />
                      in one report
                      <br />
                      and click
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        VIEW SELECTED
                        <br />
                        RESULTS
                      </span>
                      <br />
                      above.
                    </span>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.filteredData.map((row) => {
              return (
                <tr key={row.id}>
                  <td>
                    <a href={row.edit_path}>{row.status}</a>
                    <br />
                    <a href={row.edit_path}>
                      <div className="icon-case_name editpencil"></div>
                    </a>
                  </td>
                  <td>{row.exam_title}</td>
                  <td>{row.nickname}</td>
                  {this.props.isAdmin ? <td>{row.group_name}</td> : null}
                  <td>{row.availability_range}</td>
                  <td>{row.purpose}</td>
                  {this.props.isAdmin ? <td>{row.default_form}</td> : null}
                  {this.props.isAdmin ? <td>{row.versions}</td> : null}
                  <td>{row.student_registered_count}</td>
                  <td>{row.student_completed_count}</td>
                  {this.props.isAdmin ? <td>{row.type_of_student}</td> : null}
                  {this.props.feature_flag_admin_upgrades ?
                    <td>
                      <div className="dashboard-tools" >
                        {(() => {
                          if (row.student_completed_count == 0) {
                            return (
                              <a href={row.delete_path} data-method="delete" data-confirm="Are you sure you want to delete this Calibrate assessment?">
                                <div className="icon-case_name delete-icon" title="Delete an assessment" aria-label="Delete an assessment"></div>
                              </a>
                            );
                          }
                        })()}
                        <div
                          className="duplicate-icon"
                          title="Duplicate an assessment"
                          aria-label="Duplicate an assessment"
                          onClick={() => this.handleOpenModal(row)}
                        >
                        </div>
                      </div>
                    </td>
                    : null
                  }
                  <td style={{ paddingRight: "20px" }}>
                    {(() => {
                      if (row.student_completed_count != 0) {
                        return (
                          <a href={row.results_path} className={"actionBtn"}>
                            Results
                          </a>
                        );
                      }
                    })()}
                  </td>
                  <td
                    style={{
                      borderLeft: "#444444 1px solid",
                      paddingLeft: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {(() => {
                        if (row.student_completed_count != 0) {
                          return (
                            <div
                              className={`icon-case_name ${
                                this.state.selectedDeliveries.indexOf(row.id) >
                                -1
                                  ? "checkedbox"
                                  : "blankcheckbox"
                              }`}
                              onClick={() => {
                                var indexOfDelivery =
                                  this.state.selectedDeliveries.indexOf(row.id);
                                if (indexOfDelivery > -1) {
                                  this.setState({
                                    selectedDeliveries:
                                      this.state.selectedDeliveries.filter(
                                        (item) => item != row.id
                                      ),
                                  });
                                } else {
                                  this.setState({
                                    selectedDeliveries: [
                                      ...this.state.selectedDeliveries,
                                      row.id,
                                    ],
                                  });
                                }
                              }}
                            ></div>
                          );
                        }
                      })()}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <AssessmentDashboardModal
          isOpen={isModalOpen}
          onClose={this.handleCloseModal}
          url={this.state.selectedRowData.duplicate_url}
          date_range={this.state.selectedRowData.date_range}
          assessment={this.state.selectedRowData.exam_title}
          purpose={this.state.selectedRowData.purpose}
          student_count={this.state.selectedRowData.student_registered_count}
        />
      </div>
    );
  }
}

export default DeliveriesTable;
