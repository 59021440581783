'use strict'

import ReactOnRails from 'react-on-rails'

import 'react-dates/initialize'
import NewEditDocApp from './NewEditDocApp'
import NewEditDoc from '../components/NewEditDoc/Component'
import DocumentList from '../components/Document/List/component'
import DocumentSetList from '../components/DocumentSet/List/component'
import DocumentSetProctorDashboard from '../components/DocumentSet/ProctorDashboard/component'
import DocumentSetReport from '../components/DocumentSet/Report/component'
import DocumentSetDocumentRelationCreateSelectList from '../components/DocumentSetDocumentRelation/CreateSelectList/component'
import DocumentSetDocumentRelationDeleteSelectList from '../components/DocumentSetDocumentRelation/DeleteSelectList/component'
import DocumentSetDocumentRelationList from '../components/DocumentSetDocumentRelation/List/component'
import DocumentSetDocumentRelationReport from '../components/DocumentSetDocumentRelation/Report/component'
import DocumentSetUserPermissionActionsPanel from '../components/DocumentSetUserPermission/ActionsPanel/component'
import DocumentSetUserPermissionStudentResetPanel from '../components/DocumentSetUserPermission/StudentResetPanel/component'
import DocumentSetUserPermissionSelectList from '../components/DocumentSetUserPermission/SelectList/component'
import DocumentSetUserPermissionCreateSelectList from '../components/DocumentSetUserPermission/CreateSelectList/component'
import GenLoadingSpinner from '../components/Gen/LoadingSpinner/component'
import GroupList from '../components/Group/List/component'
import GroupDocumentCreateBatchSelectList from '../components/GroupDocument/CreateBatchSelectList/component'
import GroupDocumentDeleteBatchSelectList from '../components/GroupDocument/DeleteBatchSelectList/component'
import GroupRoleDefinitionUserReport from '../components/GroupRoleDefinitionUser/Report/component'
import GroupRoleDefinitionUserSelectList from '../components/GroupRoleDefinitionUser/SelectList/component'
import UserDocumentSetReport from '../components/User/DocumentSetReport/component'
import UserStudentDocumentSetReport from '../components/User/StudentDocumentSetReport/component'
import UserList from '../components/User/List/component'
import ReportsApp from './ReportsApp'
import ReportsMultiDashboard from '../components/Reports/MultiDashboard'
import QuoteInterfaceQuote from '../components/QuoteInterface/Quote/component'
import QuoteInterfaceQuoteNextYear from '../components/QuoteInterface/Quote/componentNextYear'
import QuoteInterfaceTable from '../components/QuoteInterface/Table/component'
import FileUploader from '../components/FileUploader/component'
import AssessmentRosteringList from '../components/Assessment/Rostering/list'
import AssessmentRosteringCard from '../components/Assessment/Rostering/card'

import AqueductTable from '../components/Reactabular/aqueduct_table'
import DocumentListApp from 'startup/DocumentListApp'
import ReusableObjectList from '../components/ReusableObject/List/component'

import DocumentSetDocumentRelationListApp from 'startup/DocumentSetDocumentRelationListApp'
import CollapsibleListItem from '../components/Gen/CollapsibleList/Item/component'
import FilterableList from '../components/Gen/FilterableList/component'
import LeadCourseAdminForm from '../components/GroupRoleDefinitionUser/Lcas/component'
import Alert from '../components/Gen/Alert/component'
import StudentResultsBonusDetailsTable from '../components/Assessment/Reports/StudentResults/identity_patterns_details_table'
import DeliveriesTable from '../components/Assessment/Dashboard/deliveries_table'
import CohortProgressesDeliveriesTable from '../components/Assessment/Reports/CohortProgressesDeliveriesTable'
import StudentLearningProgressTable from '../components/Assessment/Reports/StudentLearningProgressTable'
import SaqPerformanceQuiz from '../components/SaqPerformanceResults/saq_performance_quiz'
import SaqClinicalDiscipline from '../components/SaqPerformanceResults/saq_clinical_discipline'
import SaqSystem from '../components/SaqPerformanceResults/saq_system'
import SaqClinicalFocus from '../components/SaqPerformanceResults/saq_clinical_focus'
import CurricularSummarySystem from '../components/Assessment/Reports/CurricularPerformanceSummary/curricular_summary_system'
import CurricularSummaryFocus from '../components/Assessment/Reports/CurricularPerformanceSummary/curricular_summary_focus'
import CurricularSummaryDiscipline from '../components/Assessment/Reports/CurricularPerformanceSummary/curricular_summary_discipline'
import AssessmentDashboardModal from '../components/Assessment/Dashboard/assessment_dashboard_modal'
import AdminAssessmentQuestions from '../components/Admin/Assessment/Questions/component'
import AdminAssessmentQuestionSets from '../components/Admin/Assessment/QuestionSets/component'
import AdminAssessmentLearningObjectives from '../components/Admin/Assessment/LearningObjectives/component'
import AdminAssessmentReusableObjects from '../components/Admin/Assessment/ReusableObjects/component'

ReactOnRails.register({
  NewEditDocApp,
  NewEditDoc,
  DocumentList,
  DocumentListApp,
  ReusableObjectList,
  DocumentSetDocumentRelationListApp,
  DocumentSetList,
  DocumentSetProctorDashboard,
  DocumentSetReport,
  DocumentSetDocumentRelationCreateSelectList,
  DocumentSetDocumentRelationDeleteSelectList,
  DocumentSetDocumentRelationList,
  DocumentSetDocumentRelationReport,
  DocumentSetUserPermissionActionsPanel,
  DocumentSetUserPermissionStudentResetPanel,
  DocumentSetUserPermissionSelectList,
  DocumentSetUserPermissionCreateSelectList,
  GenLoadingSpinner,
  GroupList,
  GroupDocumentCreateBatchSelectList,
  GroupDocumentDeleteBatchSelectList,
  GroupRoleDefinitionUserReport,
  GroupRoleDefinitionUserSelectList,
  UserDocumentSetReport,
  UserStudentDocumentSetReport,
  UserList,
  ReportsApp,
  ReportsMultiDashboard,
  QuoteInterfaceQuote,
  QuoteInterfaceQuoteNextYear,
  QuoteInterfaceTable,
  FileUploader,
  AssessmentRosteringList,
  AssessmentRosteringCard,
  AqueductTable,
  CollapsibleListItem,
  FilterableList,
  LeadCourseAdminForm,
  Alert,
  StudentResultsBonusDetailsTable,
  DeliveriesTable,
  CohortProgressesDeliveriesTable,
  StudentLearningProgressTable,
  SaqPerformanceQuiz,
  SaqClinicalDiscipline,
  SaqSystem,
  SaqClinicalFocus,
  CurricularSummarySystem,
  CurricularSummaryFocus,
  CurricularSummaryDiscipline,
  AssessmentDashboardModal,
  AdminAssessmentQuestions,
  AdminAssessmentQuestionSets,
  AdminAssessmentLearningObjectives,
  AdminAssessmentReusableObjects,
});

ReactOnRails.setOptions({ turbo: true });

window.renderReactOnRailsComponent = function(name, props, domNodeId, hydrate) {
  ReactOnRails.render(name, props, domNodeId, hydrate);
}

window.renderAllChildReactOnRailsComponents = function(element) {
  const els = element.getElementsByClassName('js-react-on-rails-component');
  for (let i = 0; i < els.length; i += 1) {
    const el = els[i];
    const name = el.getAttribute('data-component-name') || '';
    const domNodeId = el.getAttribute('data-dom-id') || '';
    const props = (el.textContent !== null) ? JSON.parse(el.textContent) : {};

    const domNode = document.getElementById(domNodeId);
    if (domNode.childNodes.length > 0) { continue; }

    ReactOnRails.render(name, props, domNodeId, false);
  }
}

function reactOnRailsTurboFrameLoaded(event) {
  window.renderAllChildReactOnRailsComponents(event.target);
}
document.addEventListener("turbo:frame-load", reactOnRailsTurboFrameLoaded);
