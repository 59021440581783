import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import client from "../../../../api/client"; // Adjust the import path if needed
import { 
  getCommonUser,
  getAdminAssessmentQuestions, 
  getAdminInitData,
  updateBulkAdminAssessmentQuestions,
  deleteBulkAdminAssessmentQuestions
} from "../../../../api/sdk.gen"; // Adjust the import path if needed
import { AssessmentQuestion, InitData, User } from "../../../../api/types.gen";
import MySwal from "../../../../ui/MySwal";
import Cookies from "js-cookie";
import Select from 'react-select';
import Import from "./components/import";
import NewSet from "./components/new_set";
import VersionsButton from "../../Versions/Button";

import { truncateWithTooltip, formatLabel } from '../../../../helpers/utils'
import { getCellValue, answerChoices } from '../../../../helpers/assessment'

const AdminAssessmentQuestions = () => {
  const [user, setUser] = useState<User>({} as User);
  const [initData, setInitData] = useState<InitData>({} as InitData);
  const [questions, setQuestions] = useState<AssessmentQuestion[]>([]);
  const [loading, setLoading] = useState(true);
  const [initDataLoaded, setInitDataLoaded] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [validityStatusOptions, setValidityStatusOptions] = useState([]);
  const [systemOptions, setSystemOptions] = useState([]);
  const [clinicalFocusOptions, setClinicalFocusOptions] = useState([]);
  const [clinicalLocationOptions, setClinicalLocationOptions] = useState([]);
  const [patientAgeOptions, setPatientAgeOptions] = useState([]);
  const [finalDiagnosisOptions, setFinalDiagnosisOptions] = useState([]);
  const [clinicalDisciplineOptions, setClinicalDisciplineOptions] = useState([]);
  const [clinicalExcellenceOptions, setClinicalExcellenceOptions] = useState([]);
  const [questionUseOptions, setQuestionUseOptions] = useState([]);
  const [questionCoursesOptions, setQuestionCoursesOptions] = useState([]);
  const [questionCourses2Options, setQuestionCourses2Options] = useState([]);
  const [examCoursesOptions, setExamCoursesOptions] = useState([]);

  const params = new URLSearchParams(location.search);
  const initialFilters: { [key: string]: string | string[] } = {};
  params.forEach((value, key) => {
    if (key.includes("[]")) {
      const cleanKey = key.replace("[]", "");
      if (!initialFilters[cleanKey]) {
        initialFilters[cleanKey] = [];
      }
      (initialFilters[cleanKey] as string[]).push(value);
    } else {
      initialFilters[key] = value;
    }
  });

  const [page, setPage] = useState<number>(parseInt(params.get('page')) || 1);
  const [per, setPer] = useState<number>(parseInt(params.get('per')) || 10);
  const [sortColumn, setSortColumn] = useState<string>(
    Cookies.get("sortColumn") || "id"
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(
    (Cookies.get("sortDirection") as "asc" | "desc") || "asc"
  );
  const [filters, setFilters] = useState<{ [key: string]: string | string[] }>(initialFilters);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const [archivedMode, setArchivedMode] = useState(false);

  const [editMode, setEditMode] = useState(false);
  // Store edited values: { [questionId]: { [columnKey]: newValue } }
  const [editedValues, setEditedValues] = useState<{
    [id: number]: { [key: string]: any };
  }>({});

  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const tableContainerRef = useRef(null);
  const [tableMaxHeight, setTableMaxHeight] = useState("auto");
  client;

  useEffect(() => {
    const updateTableHeight = () => {
      const headerHeight = headerRef.current?.offsetHeight || 0;
      const footerHeight = footerRef.current?.offsetHeight || 0;
      const viewportHeight = window.innerHeight;

      const maxHeight = Math.max(500, viewportHeight - headerHeight - footerHeight - 100);
      setTableMaxHeight(`${maxHeight}px`);
    };

    // Set height on mount and resize
    setTimeout(() => {
      updateTableHeight();
    }, 1000)

    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, []);
  
  const fetchUser = async () => {
    setLoading(true)
    try {
      const response = await getCommonUser();
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false)
    }
  }

  const fetchInitData = async () => {
    setLoading(true)
    try {
      const response = await getAdminInitData();
      setInitData(response.data);
    } catch (error) {
      console.error("Error fetching init data:", error);
    } finally {
      setLoading(false)
    }
  }

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const params: any = {
        page,
        per,
      };

      if (sortColumn && sortDirection) {
        params.sort_col = sortColumn;
        params.sort_dir = sortDirection;
      }

      params.archived = archivedMode

      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          params[`filters[${key}][]`] = value;
        } else {
          params[`filters[${key}]`] = value;
        }
      });

      const response = await getAdminAssessmentQuestions({
        query: params,
      });

      setQuestions(response.data.data);
      setRowCount(response.data.meta?.count || 0);
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0;
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchInitData();
  }, [])

  useEffect(() => {
    if (initData && Object.keys(initData).length > 0) {
      setValidityStatusOptions(initData.question_status || []);
      setSystemOptions(initData.systems || []);
      setClinicalFocusOptions(initData.clinical_focus || []);
      setClinicalLocationOptions(initData.clinical_locations || []);
      setPatientAgeOptions(initData.patient_age || []);
      setFinalDiagnosisOptions(initData.final_diagnosis || []);
      setClinicalDisciplineOptions(initData.clinical_disciplines || []);
      setClinicalExcellenceOptions(initData.clinical_excellence || []);
      setQuestionUseOptions(initData.current_use || []);
      setQuestionCoursesOptions(initData.question_courses || []);
      setQuestionCourses2Options(initData.question_courses_2 || []);
      setExamCoursesOptions(initData.exam_courses || [])
      setInitDataLoaded(true)
    }
  }, [initData])

  useEffect(() => {
    fetchQuestions();
  }, [page, per, sortColumn, sortDirection, filters, archivedMode]);

  // Update URL params when filters change
  useEffect(() => {
    const newParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => newParams.append(`${key}[]`, val));
      } else {
        newParams.set(key, value);
      }
    });

    if (page) newParams.set('page', page.toString());
    if (per) newParams.set('per', per.toString());

    // Update the browser's URL without reloading the page
    const newUrl = `${window.location.pathname}?${newParams.toString()}`;
    window.history.pushState(null, "", newUrl);
  }, [page, per, filters]);

  useEffect(() => {
    Cookies.set("sortColumn", sortColumn || "");
    Cookies.set("sortDirection", sortDirection || "");
  }, [sortColumn, sortDirection]);

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleFilterChange = (column: string, value: string) => {
    const updatedFilters = {
      ...filters,
      [column]: value,
    };
    setFilters(updatedFilters);
    setPage(1);
  };

  const handleFilterChangeMulti = (column: string, selectedOptions: { label: string; value: string }[]) => {
    const selectedValues = selectedOptions.map(option => option.value);

    const updatedFilters = {
      ...filters,
      [column]: selectedValues,
    };
    setFilters(updatedFilters);
    setPage(1);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = questions.map((q) => q.id);
      setSelectedRows(new Set(allIds));
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleSelectRow = (id: number, isChecked: boolean) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (isChecked) {
      updatedSelectedRows.add(id);
    } else {
      updatedSelectedRows.delete(id);
    }
    setSelectedRows(updatedSelectedRows);
  };

  const handleSaveChanges = async () => {
    const confirmSave = await MySwal.fire({
      title: "Are you sure you want to save the changes?",
      showCancelButton: true,
      confirmButtonText: "Save",
    });

    if (confirmSave.isConfirmed) {
      const formattedData = Object.entries(editedValues).map(([questionId, values]) => ({
        id: Number(questionId),
        ...values,
      }));

      const result = await updateBulkAdminAssessmentQuestions({
        body: { data: formattedData },
      });

      if (result.error) {
        MySwal.fire("Error!", `Failed to save changes. ${(result as { error: { error: string } }).error.error}`, "error");
      } else {
        setEditedValues({});
        setEditMode(false);
        fetchQuestions();
        MySwal.fire("Saved!", "Changes have been saved.", "success");
      }
    }
  };

  const handleCancelChanges = async () => {
    if (Object.keys(editedValues).length > 0) {
      const confirmCancel = await MySwal.fire({
        title: "You have unsaved changes. Do you want to discard them?",
        showCancelButton: true,
        confirmButtonText: "Discard",
      });

      if (!confirmCancel.isConfirmed) return;
    }
    setEditedValues({});
    setEditMode(false);
  };

  const handleArchiveSelected = async () => {
    const confirmArchive = await MySwal.fire({
      title: "Are you sure you want to archive the selected items?",
      showCancelButton: true,
      confirmButtonText: "Archive",
    });

    if (confirmArchive.isConfirmed) {
      const archivedAt = new Date().toISOString();
      const data = Array.from(selectedRows).map(id => ({
        id,
        archived_at: archivedAt,
      }));

      const result = await updateBulkAdminAssessmentQuestions({ body: { data } });

      if (result.error) {
        MySwal.fire("Error!", `Error archiving selected. ${(result as { error: { error: string } }).error.error}`, "error");
      } else {
        setSelectedRows(new Set());
        fetchQuestions();
        MySwal.fire("Archived!", "Items have been archived.", "success");
      }
    }
  };

  const handleRestoreSelected = async () => {
    const confirmArchive = await MySwal.fire({
      title: "Restore Items?",
      text: "Are you sure you want to restore the selected items?",
      showCancelButton: true,
      confirmButtonText: "Restore",
    });

    if (confirmArchive.isConfirmed) {
      const data = Array.from(selectedRows).map(id => ({
        id,
        archived_at: null,
      }));

      const result = await updateBulkAdminAssessmentQuestions({ body: { data } });

      if (result.error) {
        MySwal.fire("Error!", `Error restoring selected. ${(result as { error: { error: string } }).error.error}`, "error");
      } else {
        setSelectedRows(new Set());
        fetchQuestions();
        MySwal.fire("Restored!", "Items have been restored.", "success");
      }
    }
  };

  const handleDeleteSelected = async () => {
    const confirmDelete = await MySwal.fire({
      title: "Are you sure you want to delete the selected items?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    });

    if (confirmDelete.isConfirmed) {
      const ids = Array.from(selectedRows);
      
      const result = await deleteBulkAdminAssessmentQuestions({ query: { ids } });

      if (result.error) {
        MySwal.fire("Error!", `Error deleting selected. ${(result as { error: { error: string } }).error.error}`, "error");
      } else {
        setSelectedRows(new Set());
        fetchQuestions();
        MySwal.fire("Deleted!", "Items have been deleted.", "success");
      }
    }
  };

  const handleExport = async (onlySelected) => {
    const confirmExport = await MySwal.fire({
      title: 'This report can take a while to produce. You can monitor its completion on the My Reports page.',
      showCancelButton: true,
      confirmButtonText: 'Yes, export it!',
      cancelButtonText: 'Cancel',
    });

    if (confirmExport.isConfirmed) {
      const exportUrl = onlySelected 
        ? `/admin/assessment/questions/export?ids=${Array.from(selectedRows).join(',')}` 
        : '/admin/assessment/questions/export';
      // Redirect to the export URL
      window.location.href = exportUrl;
    };
  };

  const handleEditToggle = () => {
    if (editMode) {
      // Exiting edit mode, discard changes
      setEditedValues({});
      setEditMode(false);
    } else {
      // Entering edit mode
      setEditMode(true);
    }
  };

  const handleArchivedToggle = () => {
    setSelectedRows(new Set());
    setArchivedMode(!archivedMode);
  };

  const handleDropdownChange = (questionId, columnKey, newValue) => {
    // If multiple rows are selected and this row is selected,
    // apply changes to all selected rows.
    const rowsToUpdate = selectedRows.has(questionId)
      ? Array.from(selectedRows)
      : [questionId];

    setEditedValues((prev) => {
      const newValues = { ...prev };
      rowsToUpdate.forEach((id) => {
        if (!newValues[id]) newValues[id] = {};
        newValues[id][columnKey] = newValue;
      });
      return newValues;
    });
  };

  const columns = [
    {
      key: "select",
      name: "",
      frozen: true,
      width: 50,
      filterType: "none",
      renderer: (item, value) => (
        <input
          type="checkbox"
          checked={selectedRows.has(item.id)}
          onChange={(e) =>
            handleSelectRow(item.id, e.target.checked)
          }
        />
      )
    },
    {
      key: "id",
      name: "ID",
      frozen: true,
      sortable: true,
      filterType: "text",
      renderer: (item, value) => (
        <a
          href={`/versions/${item.editing_document.scheduled_review_version_id}/edit`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "case_summary_identifier",
      name: "ITEM LABEL",
      frozen: true,
      sortable: true,
      filterType: "text",
      options: questionCoursesOptions,
      renderer: (item, value) => (
        <a
          href={`/versions/${item.editing_document.scheduled_review_version_id}/edit`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "course_name",
      name: "COURSE",
      sortable: false,
      filterKey: 'course_name',
      filterType: "multiselect",
      options: questionCourses2Options
    },
    {
      key: "document.title",
      name: "CASE",
      sortable: true,
      filterKey: 'document_id',
      filterType: "multiselect",
      options: questionCoursesOptions,
      renderer: (item, value) => (
        <a
          href={`/documents/${item.document_id}`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "current_use",
      name: "QUESTION USE",
      sortable: true,
      filterType: "multiselect",
      options: questionUseOptions,
      editable: true,
    },
    {
      key: "efficiency_breakpoints_seconds",
      name: "SCORABLE?",
      sortable: true,
      filterType: "select",
      options: ["Yes", "No"],
    },
    {
      key: "content.vignette",
      name: "VIGNETTE",
      sortable: true,
      filterType: "text",
    },
    {
      key: "content.stem",
      name: "STEM",
      sortable: true,
      filterType: "text",
    },
    {
      key: "content.images",
      name: "IMAGES",
      sortable: true,
      filterType: "none",
    },
    {
      key: "content.correct_answer_index",
      name: "CORRECT ANSWER",
      sortable: true,
      filterType: "multiselect",
      options: answerChoices,
      editable: true,
    },
    {
      key: "choice_a",
      name: "CHOICE A",
      sortable: true,
      filterType: "text",
    },
    {
      key: "choice_b",
      name: "CHOICE B",
      sortable: true,
      filterType: "text",
    },
    {
      key: "choice_c",
      name: "CHOICE C",
      sortable: true,
      filterType: "text",
    },
    {
      key: "choice_d",
      name: "CHOICE D",
      sortable: true,
      filterType: "text",
    },
    {
      key: "choice_e",
      name: "CHOICE E",
      sortable: true,
      filterType: "text",
    },
    {
      key: "difficulty",
      name: "DIFFICULTY",
      sortable: true,
      filterType: "text",
    },
    {
      key: "discrimination",
      name: "DISCRIMINATION",
      sortable: true,
      filterType: "text",
    },
    {
      key: "status",
      name: "VALIDITY STATUS",
      sortable: true,
      filterType: "multiselect",
      options: validityStatusOptions,
      editable: true,
    },
    {
      key: "learning_objective_reusable_object.learning_objective_name",
      name: "LEARNING OBJECTIVES",
      sortable: true,
      filterType: "text",
      filterKey: 'learning_objective_name'
    },
    {
      key: "learning_objective_reusable_object.learning_objective_id",
      name: "LO ID",
      sortable: true,
      filterType: "text",
      filterKey: 'learning_objective_id',
      renderer: (item, value) => (
        <a
          href={`/admin/assessment/learning_objectives?id=${value}`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "learning_objective_reusable_object.reusable_object_name",
      name: "TEACHING POINTS",
      sortable: true,
      filterType: "text",
      filterKey: 'reusable_object_name',
    },
    {
      key: "learning_objective_reusable_object.reusable_object_id",
      name: "TP ID",
      sortable: true,
      filterType: "text",
      filterKey: 'reusable_object_id',
      renderer: (item, value) => (
        <a
          href={`/admin/assessment/reusable_objects?id=${value}`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "question_set_ids",
      name: "SETS",
      sortable: true,
      filterType: "text",
      filterKey: 'question_set_id',
      renderer: (item, value) => (
        <div>
          <a
            href={`/admin/assessment/question_sets?question_id=${item.id}`}
            target='_blank'
            rel="noopener noreferrer"
          >
            {item.question_sets.length} items
          </a>

          {item.question_sets.map((item) => (
            <div key={item.id}>
              <a
                href={`/admin/assessment/question_sets?id=${item.id}`}
                target='_blank'
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
            </div>
          ))}
        </div>
      )
    },
    {
      key: "content.answer_explanation",
      name: "ANSWER EXPLANATION",
      sortable: true,
      filterType: "text",
    },
    {
      key: "system",
      name: "SYSTEM",
      sortable: true,
      filterType: "multiselect",
      options: systemOptions,
      editable: true,
    },
    {
      key: "clinical_focus",
      name: "CLINICAL FOCUS",
      sortable: true,
      filterType: "multiselect",
      options: clinicalFocusOptions,
      editable: true,
    },
    {
      key: "clinical_location",
      name: "CLINICAL LOCATION",
      sortable: true,
      filterType: "multiselect",
      options: clinicalLocationOptions,
      editable: true,
    },
    {
      key: "patient_age",
      name: "PATIENT AGE",
      sortable: true,
      filterType: "multiselect",
      options: patientAgeOptions,
      editable: true,
    },
    {
      key: "final_diagnosis",
      name: "FINAL DIAGNOSIS",
      sortable: true,
      filterType: "multiselect",
      options: finalDiagnosisOptions,
      editable: true,
    },
    {
      key: "clinical_disciplines",
      name: "CLINICAL DISCIPLINE",
      sortable: true,
      filterType: "multiselect",
      options: clinicalDisciplineOptions,
      editable: true,
    },
    {
      key: "clinical_excellence",
      name: "CLINICAL EXCELLENCE",
      sortable: true,
      filterType: "multiselect",
      options: clinicalExcellenceOptions,
      editable: true,
    },
    {
      key: "created_at",
      name: "DATE CREATED",
      sortable: true,
      filterType: "none",
    },
    {
      key: "updated_at",
      name: "DATE MODIFIED",
      sortable: true,
      filterType: "none",
    },
  ];

  const getEditedValue = (questionId, columnKey, originalValue) => {
    if (editedValues[questionId] && editedValues[questionId][columnKey] !== undefined) {
      return editedValues[questionId][columnKey];
    }
    return originalValue;
  };

  // Render cell content
  const renderCellContent = (question, col) => {
    const originalValue = getCellValue(question, col.key);

    if (editMode && col.editable) {
      const options = col.options || [];
      const currentValue = getEditedValue(question.id, col.key, originalValue);

      return (
        <select
          value={currentValue === "-" ? "" : currentValue}
          onChange={(e) => handleDropdownChange(question.id, col.key, e.target.value)}
        >
          <option value="">-</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {formatLabel(option)}
            </option>
          ))}
        </select>
      );
    }

    if (col.renderer) {
      return col.renderer(question, originalValue)
    }

    return <div dangerouslySetInnerHTML={{ __html: originalValue }} />;
  };

  const getMultiselectOptions = (options = []) => {
    const res = [];

    if (typeof options[0] === 'object' && options[0] !== null && !Array.isArray(options[0])) {
      options
        .sort((a, b) => a.title.localeCompare(b.title)) // Ensure sorting is safe
        .forEach(option => {
          res.push({ label: formatLabel(option.title.split(/:| - /)[0]), value: option.id });
        });
    } else {
      options.forEach(option => {
        res.push({ label: formatLabel(option), value: option });
      });
    }

    return res;
  };

  const getLabelById = (options, id) => {
    const option = options.find((option) => option.id == id)

    return option?.title
  }

  if (!initDataLoaded) {
    return <div>Loading...</div>
  }

  return (
    <div className="page-wrap">
      <div className="header-wrap" ref={headerRef}>
        <div className="header-1" style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
          <h1>Questions</h1>
          { user.type === 'Admin' &&
            <>
              <Import />
              <NewSet selectedRows={selectedRows} examCourses={examCoursesOptions}/>
              <button onClick={() => handleExport(false)}>Export All</button>
              <button onClick={() => handleExport(true)}>Export Selected</button>
            </> }
        </div>
        { user.type === 'Admin' &&
          <div className="header-2" style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
            <div>
              <button onClick={handleArchivedToggle}>{archivedMode ? 'Show Active' : 'Show Archived'}</button>
            </div>
            <div>
              {!editMode && (
                <>
                  <button onClick={handleEditToggle}>Edit Mode</button>
                </>
              )}
              {editMode && (
                <>
                  <button onClick={handleSaveChanges} disabled={!Object.keys(editedValues).length}>Save Changes</button>
                  <button onClick={handleCancelChanges}>Cancel</button>
                </>
              )}
            </div>
            <div>
              { !archivedMode && (<button onClick={handleArchiveSelected} disabled={selectedRows.size === 0}>Archive Selected</button>) }
              { archivedMode && (<button onClick={handleRestoreSelected} disabled={selectedRows.size === 0}>Restore Selected</button>) }
              <button onClick={handleDeleteSelected} disabled={selectedRows.size === 0}>Delete Selected</button>
            </div>
          </div> }
      </div>
      <div className="table-container" style={{ maxHeight: tableMaxHeight }} ref={tableContainerRef}>
        <table className="table-with-fixed-columns">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th
                  key={col.key}
                  className={`${col.frozen ? "frozen-column" : ""} ${
                    index === 2 ? "frozen-divider" : ""
                  }`}
                >
                  {col.key === "select" ? (
                    <input
                      type="checkbox"
                      checked={
                        selectedRows.size === questions.length &&
                        questions.length > 0
                      }
                      ref={(el) => {
                        if (el) {
                          el.indeterminate =
                            selectedRows.size > 0 &&
                            selectedRows.size < questions.length;
                        }
                      }}
                      onChange={handleSelectAll}
                    />
                  ) : (
                    <div className="header-content">
                      <div>
                        <span>{col.name}</span>
                        {col.sortable && (
                          <span
                            className="sort-icon"
                            onClick={() => handleSort(col.key)}
                            style={{cursor:'pointer'}}
                          >
                            {sortColumn === col.key ? (
                              sortDirection === "asc" ? (
                                " 🔼"
                              ) : (
                                " 🔽"
                              )
                            ) : (
                              " ↕️"
                            )}
                          </span>
                        )}
                      </div>
                      <div>
                        {col.filterType === "text" && (
                          <input
                            type="text"
                            placeholder="Filter"
                            defaultValue={filters[col.filterKey || col.key] || ""}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilterChange(col.filterKey || col.key, (e.target as HTMLInputElement).value);
                              }
                            }}
                            onBlur={(e) => handleFilterChange(col.filterKey || col.key, (e.target as HTMLInputElement).value)}
                            // onChange={(e) => console.log('changed')}
                          />
                        )}
                        {col.filterType === "multiselect" && (
                          <Select
                            isMulti
                            isClearable
                            isSearchable
                            options={getMultiselectOptions(col.options)}
                            defaultValue={getMultiselectOptions(col.options).filter(option => filters[col.filterKey || col.key]?.includes(option.value.toString()))}
                            onChange={selected => handleFilterChangeMulti(col.filterKey || col.key, selected as { label: string; value: string; }[])}
                          />
                        )}
                        {col.filterType === "select" && (
                          <select
                            value={filters[col.filterKey || col.key] || ""}
                            onChange={(e) =>
                              handleFilterChange(col.filterKey || col.key, e.target.value)
                            }
                          >
                            <option value="">All</option>
                            {col.options && col.options.map((option) => (
                              <option key={option} value={option}>
                                {formatLabel(option)}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                  )}
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {questions.map((question) => (
              <tr 
                key={question.id} 
                className={`
                  ${selectedRows.has(question.id) ? 'selected-row' : ''} 
                  ${question.archived_at ? 'archived-row' : ''} 
                `}
              >
                {columns.map((col, index) => (
                  <td
                    key={col.key}
                    className={`
                      ${col.frozen ? "frozen-column" : ""}
                      ${index === 2 ? "frozen-divider" : ""}
                      ${(editedValues[question.id] && editedValues[question.id][col.key] !== undefined) ? "highlight-col" : ""}
                    `}
                  >
                    {renderCellContent(question, col)}
                  </td>
                ))}
                <td>
                  <VersionsButton itemId={question.id} itemType="Assessment::Question"  itemData={question} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-wrap" ref={footerRef}>
        <div className="pagination-per">
          Displaying 
          <select
            value={per}
            onChange={(e) => {
              setPer(parseInt(e.target.value));
              setPage(1);
            }}
          >
            {[10, 20, 50, 100, 500].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
          items of {rowCount}
        </div>
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(rowCount / per)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selected) => setPage(selected.selected + 1)}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default AdminAssessmentQuestions;
