import * as React from 'react';
import { useEffect, useState } from "react";

import { getAdminAssessmentQuestions, createAdminAssessmentQuestionSet } from "../../../../../api/sdk.gen";
import { AssessmentQuestion } from "../../../../../api/types.gen";
import GenModal from '../../../../Gen/Modal/component';
import { truncateWithTooltip } from '../../../../../helpers/utils';
import MySwal from "../../../../../ui/MySwal";

interface AdminAssessmentQuestionsNewSetProps {
  selectedRows: Set<number>;
  examCourses: { id: string; title: string }[];
}

const AdminAssessmentQuestionsNewSet: React.FC<AdminAssessmentQuestionsNewSetProps> = ({ selectedRows, examCourses }) => {
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [questions, setQuestions] = useState<AssessmentQuestion[]>([]);
  const [storedSelectedRows, setStoredSelectedRows] = useState<Set<number>>(() => {
    const saved = localStorage.getItem("storedSelectedRows");
    return saved ? new Set(JSON.parse(saved)) : new Set();
  });
  const [title, setTitle] = useState("");
  const [selectedExamCourse, setSelectedExamCourse] = useState<string | null>(null);

  // Save to local storage whenever storedSelectedRows changes
  useEffect(() => {
    localStorage.setItem("storedSelectedRows", JSON.stringify(Array.from(storedSelectedRows)));

    // close if no questions left
    if(storedSelectedRows.size == 0) {
      setModalOpen(false);
    }
  }, [storedSelectedRows]);

  // Handler for "Add to Set" button
  const handleAddToSet = () => {
    const updatedSelectedRows = Array.from(selectedRows); // Convert Set to Array
    const newStoredRows = new Set([...storedSelectedRows, ...updatedSelectedRows]); // Merge and deduplicate
    setStoredSelectedRows(newStoredRows); // Update state
  };

  // Handler for "Create Question Set" button
  const handleCreateQuestionSet = () => {
    fetchQuestions();
  };

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const params: any = {
        page: 1,
        per: 1000,
      };

      params[`filters[id][]`] = Array.from(storedSelectedRows);

      const response = await getAdminAssessmentQuestions({
        query: params,
      });

      setQuestions(response.data.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
      setModalOpen(true);
    }
  };

  // Handler for "Remove" button
  const handleRemove = (id: number) => {
    setQuestions(questions.filter((question) => question.id !== id));
    setStoredSelectedRows((prev) => {
      const updatedRows = new Set(prev);
      updatedRows.delete(id);
      return updatedRows;
    });
  };

  // Handler for "Save Question Set" button
  const handleSaveQuestionSet = async () => {
    // Validation: Check if there are any archived questions
    const archivedQuestions = questions.filter((question) => question.archived_at);
    if (archivedQuestions.length > 0) {
      MySwal.fire(
        "Validation Error!",
        "Cannot create a question set with archived questions.",
        "error"
      );
      return;
    }

    // Validation: Ensure title and selected exam course are provided
    if (!title || selectedExamCourse === null) {
      MySwal.fire(
        "Validation Error!",
        "Please provide a title and select an exam course.",
        "error"
      );
      return;
    }

    const result = await createAdminAssessmentQuestionSet({
      body: {
        title,
        exam_course: selectedExamCourse,
        question_ids: Array.from(storedSelectedRows),
      }
    });

    if (result.error) {
      console.error("Error saving question set:", result.error);
      MySwal.fire("Error!", "Failed to save question set. - " + (result as { error: { error: string } }).error.error, "error");
    } else {
      setModalOpen(false);
      setStoredSelectedRows(new Set());
      setQuestions([]);
      setTitle("");
      setSelectedExamCourse(null);
      MySwal.fire("Created!", "Question set have been created.", "success");
    }
  };

  // Handler for "Remove All and Close" button
  const handleRemoveAllAndClose = () => {
    setStoredSelectedRows(new Set());
    setQuestions([]);
    setModalOpen(false);
  };

  // Handler for "Close" button
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <button disabled={selectedRows.size === 0} onClick={handleAddToSet}>Add to Set</button>
      <button disabled={storedSelectedRows.size === 0} onClick={handleCreateQuestionSet}>
        Create Question Set ({storedSelectedRows.size})
      </button>
      <GenModal open={modalOpen}>
        <div className="gen-modal-panel gen-modal-panel-large">
          <h5>Create Question Set</h5>
          <div>
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter question set title"
            />
          </div>
          <div>
            <label>Exam Course:</label>
            <select
              value={selectedExamCourse || ""}
              onChange={(e) => setSelectedExamCourse(e.target.value)}
            >
              <option value="" disabled>Select an exam course</option>
              {examCourses.map((course) => (
                <option key={course.id} value={course.id}>{course.title}</option>
              ))}
            </select>
          </div>
          <div>
            <label>The following Questions will be used to create the Question Set:</label>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>ITEM LABEL</th>
                  <th>VIGNETTE</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question) => (
                  <tr 
                    key={question.id} 
                    className={`
                      ${question.archived_at ? 'archived-row' : ''} 
                    `}
                  >
                    <td>{question.id}</td>
                    <td>{question.case_summary_identifier}</td>
                    <td>
                      <div dangerouslySetInnerHTML={{ __html: truncateWithTooltip(question.content.vignette, 100) }} />
                    </td>
                    <td><button onClick={() => handleRemove(question.id)}>Remove</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="controls">
            <button onClick={handleSaveQuestionSet}>Save Question Set</button>
            <button onClick={handleRemoveAllAndClose}>Remove All and Close</button>
            <button onClick={handleClose}>Close</button>
          </div>
        </div>
      </GenModal>
    </>
  );
};

export default AdminAssessmentQuestionsNewSet;
