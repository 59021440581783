import * as React from 'react';
import { useEffect, useState } from "react";
import VersionsList from "./List";
import GenModal from '../../Gen/Modal/component';

const VersionsButton = ({ itemId, itemType, itemData }) => {
  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  const openVersionHistory = () => {
    setHistoryModalOpen(true)
  };

  const closeVersionHistory = () => {
    setHistoryModalOpen(false)
  };

  return (
    <>
      <div style={{cursor: 'pointer'}} onClick={openVersionHistory}>Versions</div>

      { historyModalOpen &&
        <GenModal open>
          <div className="gen-modal-panel gen-modal-panel-large">
            <h5>Question versions history</h5>
            <VersionsList itemId={itemId} itemType={itemType} itemData={itemData} />
            <div style={{textAlign: 'center'}}>
              <button onClick={closeVersionHistory}>
                Close
              </button>
            </div>
          </div>
        </GenModal> }
    </>
  );
};

export default VersionsButton;

