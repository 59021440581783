import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import client from "../../../../api/client"; // Adjust the import path if needed
import {
  getAdminInitData,
  getAdminLearningObjectives, 
} from "../../../../api/sdk.gen"; // Adjust the import path if needed
import { InitData, LearningObjective } from "../../../../api/types.gen";
import Cookies from "js-cookie";
import Select from 'react-select';

import VersionsButton from "../../Versions/Button";

import { formatDate } from '../../../../helpers/dates'
import { truncateWithTooltip } from '../../../../helpers/utils'

const AdminAssessmentLearningObjectives = () => {
  const [initData, setInitData] = useState<InitData>({} as InitData);
  const [initDataLoaded, setInitDataLoaded] = useState(false);
  const [learningObjectives, setLearningObjectives] = useState<LearningObjective[]>([]);
  const [loading, setLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [questionCoursesOptions, setQuestionCoursesOptions] = useState([]);
  
  const [sortColumn, setSortColumn] = useState<string>(
    Cookies.get("sortColumn") || "id"
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(
    (Cookies.get("sortDirection") as "asc" | "desc") || "asc"
  );

  const params = new URLSearchParams(location.search);
  const initialFilters: { [key: string]: string | string[] } = {};
  params.forEach((value, key) => {
    if (key.includes("[]")) {
      const cleanKey = key.replace("[]", "");
      if (!initialFilters[cleanKey]) {
        initialFilters[cleanKey] = [];
      }
      (initialFilters[cleanKey] as string[]).push(value);
    } else {
      initialFilters[key] = value;
    }
  });

  const [page, setPage] = useState<number>(parseInt(params.get('page')) || 1);
  const [per, setPer] = useState<number>(parseInt(params.get('per')) || 10);
  const [filters, setFilters] = useState<{ [key: string]: string | string[] }>(initialFilters);
  
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const tableContainerRef = useRef(null);
  const [tableMaxHeight, setTableMaxHeight] = useState("auto");

  client;

  useEffect(() => {
    const updateTableHeight = () => {
      const headerHeight = headerRef.current?.offsetHeight || 0;
      const footerHeight = footerRef.current?.offsetHeight || 0;
      const viewportHeight = window.innerHeight;

      const maxHeight = Math.max(500, viewportHeight - headerHeight - footerHeight - 100);
      setTableMaxHeight(`${maxHeight}px`);
    };

    // Set height on mount and resize
    setTimeout(() => {
      updateTableHeight();
    }, 1000)

    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, []);

  useEffect(() => {
    fetchInitData();
  }, [])

  useEffect(() => {
    if (initData && Object.keys(initData).length > 0) {
      setQuestionCoursesOptions(initData.question_courses || []);
      setInitDataLoaded(true)
    }
  }, [initData])

  // Update URL params when filters change
  useEffect(() => {
    const newParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => newParams.append(`${key}[]`, val));
      } else {
        newParams.set(key, value);
      }
    });

    if (page) newParams.set('page', page.toString());
    if (per) newParams.set('per', per.toString());

    // Update the browser's URL without reloading the page
    const newUrl = `${window.location.pathname}?${newParams.toString()}`;
    window.history.pushState(null, "", newUrl);
  }, [page, per, filters]);

  const fetchInitData = async () => {
    setLoading(true)
    try {
      const response = await getAdminInitData();
      setInitData(response.data);
    } catch (error) {
      console.error("Error fetching init data:", error);
    } finally {
      setLoading(false)
    }
  }

  const fetchLearningObjectives = async () => {
    setLoading(true);
    try {
      const params: any = {
        page,
        per,
      };

      if (sortColumn && sortDirection) {
        params.sort_col = sortColumn;
        params.sort_dir = sortDirection;
      }

      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          params[`filters[${key}][]`] = value;
        } else {
          params[`filters[${key}]`] = value;
        }
      });

      const response = await getAdminLearningObjectives({
        query: params,
      });

      setLearningObjectives(response.data.data);
      setRowCount(response.data.meta?.count || 0);
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0;
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchLearningObjectives();
  }, [page, per, sortColumn, sortDirection, filters]);

  useEffect(() => {
    Cookies.set("sortColumn", sortColumn || "");
    Cookies.set("sortDirection", sortDirection || "");
  }, [sortColumn, sortDirection]);

  const formatLabel = (key: string) => {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase())
  }

  const handleFilterChange = (column: string, value: string) => {
    const updatedFilters = {
      ...filters,
      [column]: value,
    };
    setFilters(updatedFilters);
    setPage(1);
  };

  const handleFilterChangeMulti = (column: string, selectedOptions: { label: string; value: string }[]) => {
    const selectedValues = selectedOptions.map(option => option.value);

    const updatedFilters = {
      ...filters,
      [column]: selectedValues,
    };
    setFilters(updatedFilters);
    setPage(1);
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const columns = [
    {
      key: "id",
      name: "ID",
      frozen: true,
      sortable: true,
      filterType: "text",
    },
    {
      key: "uid",
      name: "UID",
      frozen: true,
      sortable: true,
      filterType: "text",
    },
    {
      key: "name",
      name: "Name",
      frozen: true,
      sortable: true,
      filterType: "text",
    },
    {
      key: "documents",
      name: "Cases",
      sortable: false,
      filterKey: 'document_id',
      filterType: "multiselect",
      options: questionCoursesOptions,
      renderer: (item, val) => (
        <div>
          {item.documents.map((item) => (
            <div key={item.id}>
              <a
                href={`/documents/${item.id}`}
                target='_blank'
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
            </div>
          ))}
        </div>
      )
    },
    {
      key: "assessment_questions",
      name: "Questions",
      frozen: true,
      sortable: false,
      filterType: "none",
      renderer: (item, val) => (
        <div>
          <a
            href={`/admin/assessment/questions?learning_objective_id=${item.id}`}
            target='_blank'
            rel="noopener noreferrer"
          >
            {item.assessment_questions.length} items
          </a>

          {item.assessment_questions.map((item) => (
            <div key={item.id}>
              <a
                href={`/admin/assessment/questions?id=${item.id}`}
                target='_blank'
                rel="noopener noreferrer"
              >
                {item.case_summary_identifier}
              </a>
            </div>
          ))}
        </div>
      )
    },
    {
      key: "publish_variant_id",
      name: "Publish variant",
      frozen: true,
      sortable: true,
      filterType: "none",
    },
    {
      key: "review_variant_id",
      name: "Review variant",
      frozen: true,
      sortable: true,
      filterType: "none",
    },
    {
      key: "maintenance_variant_id",
      name: "Maintenanc variant",
      frozen: true,
      sortable: true,
      filterType: "none",
    },
    {
      key: "created_at",
      name: "DATE CREATED",
      sortable: true,
      filterType: "none",
    },
    {
      key: "updated_at",
      name: "DATE MODIFIED",
      sortable: true,
      filterType: "none",
    },
  ];

  const getMultiselectOptions = (options = []) => {
    const res = [];

    if (typeof options[0] === 'object' && options[0] !== null && !Array.isArray(options[0])) {
      options
        .sort((a, b) => a.title.localeCompare(b.title)) // Ensure sorting is safe
        .forEach(option => {
          res.push({ label: formatLabel(option.title.split(/:| - /)[0]), value: option.id });
        });
    } else {
      options.forEach(option => {
        res.push({ label: formatLabel(option), value: option });
      });
    }

    return res;
  };

  const formatCellValue = (value) => {
    if (Array.isArray(value)) {
      return value.length > 0 ? value.join(", ") : "-";
    }
    if (value == null || value === "") return "-";

    const stringValue = String(value);
    return stringValue.length > 200 ? truncateWithTooltip(stringValue) : stringValue;
  };

  const resolveNestedValue = (obj, key) =>
    key.split(".").reduce((acc, field) => (acc && acc[field] ? acc[field] : null), obj);

  const getSpecialCellValue = (item, key) => {
    if (key === "updated_at" || key === "created_at") return formatDate(item[key]);

    return null;
  };

  // Main function for getting cell value
  const getCellValue = (item, key) => {
    const specialValue = getSpecialCellValue(item, key);
    if (specialValue !== null) return specialValue;

    const value = resolveNestedValue(item, key);
    return formatCellValue(value);
  };

  // Render cell content
  const renderCellContent = (item, col) => {
    const originalValue = getCellValue(item, col.key);

    if (col.renderer) {
      return col.renderer(item, originalValue)
    }

    return <div dangerouslySetInnerHTML={{ __html: originalValue }} />;
  };

  if (!initDataLoaded) {
    return <div>Loading...</div>
  }

  return (
    <div className="page-wrap">
      <div className="header-wrap" ref={headerRef}>
        <div className="header-1" style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
          <h1>Learning Objectives</h1>
        </div>
      </div>
      <div className="table-container" style={{ maxHeight: tableMaxHeight }} ref={tableContainerRef}>
        <table className="table-with-fixed-columns">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th
                  key={col.key}
                >
                  <div className="header-content">
                    <div>
                      <span>{col.name}</span>
                      {col.sortable && (
                        <span
                          className="sort-icon"
                          onClick={() => handleSort(col.key)}
                          style={{cursor:'pointer'}}
                        >
                          {sortColumn === col.key ? (
                            sortDirection === "asc" ? (
                              " 🔼"
                            ) : (
                              " 🔽"
                            )
                          ) : (
                            " ↕️"
                          )}
                        </span>
                      )}
                    </div>
                    <div>
                      {col.filterType === "text" && (
                        <input
                          type="text"
                          placeholder="Filter"
                          defaultValue={filters[col.filterKey || col.key] || ""}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleFilterChange(col.filterKey || col.key, (e.target as HTMLInputElement).value);
                            }
                          }}
                          onBlur={(e) => handleFilterChange(col.filterKey || col.key, (e.target as HTMLInputElement).value)}
                          // onChange={(e) => console.log('changed')}
                        />
                      )}
                      {col.filterType === "multiselect" && (
                        <Select
                          isMulti
                          isClearable
                          isSearchable
                          options={getMultiselectOptions(col.options)}
                          defaultValue={getMultiselectOptions(col.options).filter(option => filters[col.filterKey || col.key]?.includes(option.value.toString()))}
                          onChange={selected => handleFilterChangeMulti(col.filterKey || col.key, selected as { label: string; value: string; }[])}
                        />
                      )}
                    </div>
                  </div>
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {learningObjectives.map((lo) => (
              <tr 
                key={lo.id} 
              >
                {columns.map((col, index) => (
                  <td key={col.key}>
                    {renderCellContent(lo, col)}
                  </td>
                ))}
                <td>
                  <VersionsButton itemId={lo.id} itemType="LearningObjective"  itemData={lo} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-wrap" ref={footerRef}>
        <div className="pagination-per">
          Displaying 
          <select
            value={per}
            onChange={(e) => {
              setPer(parseInt(e.target.value));
              setPage(1);
            }}
          >
            {[10, 20, 50, 100, 500].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
          items of {rowCount}
        </div>
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(rowCount / per)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(selected) => setPage(selected.selected + 1)}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default AdminAssessmentLearningObjectives;
