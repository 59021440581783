import React from 'react'

import { full_url } from 'helpers/general'

export default class AssessmentRosteringCard extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };

  }

  getResultsCell(rostering) {
    if (rostering.status === "Paused" || (rostering.status === "In progress" && rostering.restart_availablity == true)) {
      return (
        <a href="#" className='actionBtn-outline'
            onClick={() => {
              let result = confirm(`Are you sure you wish to resume your assessment now?`)
              if (result == true) {
                window.location.href = full_url(rostering.exam_url)
              }
            }} >Resume</a>
      )
    } else if (["Completed", "Auto-submitted - Exam Timeout", "Auto-submitted - Pause Timeout"].includes(rostering.status)) {
      return <a href={rostering.student_results_report_url} className='actionBtn-outline'>Results</a>
    }
    return 'Not Available'
  }

  render() {
    return (
      <>
        <div className='rosteringCard'>
          <div className='rosteringCardTitleRow'>
            <div className={this.state.collapsed ? 'rosteringCardOpen' : 'rosteringCardClosed'} onClick={() => {
              this.setState({collapsed: !this.state.collapsed})
            }}></div>
            <h2 className='rosteringCardTitle'>{this.props.course}</h2>
          </div>

          <table className='rosteringDetailsTable' style={{display: this.state.collapsed ? 'none' : 'table'}}>
            <thead>
              <tr>
                <th width='10%'>Version</th>
                <th width='30%'>Status</th>
                <th width='30%'>Assessment Window</th>
                <th width='30%'>Report</th>
              </tr>
            </thead>
            <tbody>
              {this.props.rosterings.map((rostering) =>{
                return (
                  <tr>
                    <td width='10%'>{rostering.personal_performance_report_name.split(' - ')[1]}</td>
                    {(rostering.demo_program || rostering.status === "Available" || rostering.status === "Restarted") ? (
                      <td width="30%">
                        <br />
                        {rostering.demo_program ? (
                          <a
                            href={rostering.demo_rostering_handler_url}
                            className="actionBtn yellowBtn"
                            data-method="put"
                            rel="nofollow"
                            data-turbo="false"
                          >
                            Begin Assessment
                          </a>
                        ) : (
                          <a href={rostering.exam_url} className="actionBtn yellowBtn">
                            Begin Assessment
                          </a>
                        )}
                        <br />
                        <br />
                        <p style={{marginLeft: 20}}>Max. time: {rostering.max_time_allowed}</p>
                      </td>
                    ) : (
                      <td width="30%">
                        {rostering.status}
                        <br />
                        {rostering.completed_at}
                      </td>
                    )}
                    <td width="30%">
                      {rostering.assessment_window_closed
                        ? "Closed"
                        : `${rostering.availability_starts_at} - ${rostering.availability_ends_at}`}
                    </td>
                    <td width="30%">
                      <br />
                      {this.getResultsCell(rostering)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

