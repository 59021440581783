import React from 'react';
import TableDownArrowImage from "images/table-down_arrow.png";
import TableUpArrowImage from "images/table-up_arrow.png";

export const sortQuizzes = (data, sortCol, sortDir, isDate, isCertainty) =>{
  console.log(sortCol)
  data.sort((a, b) => {
    let comparison = 0;
    let fa, fb
    if(isDate){
      fa = new Date(a[sortCol]);
      fb = new Date(b[sortCol]);
    }else if(isCertainty){
      fa = a[sortCol].very_certain;
      fb = b[sortCol].very_certain;

    }else {
       fa = a[sortCol];
       fb = b[sortCol];
    }
    if (fa < fb) {
      comparison = -1;
  } else if (fa > fb) {
      comparison = 1;
  }
    return sortDir === 'asc' ? comparison : -comparison;
   
});
  return data
}

export const sortImage = (currentSortCol, sortCol, onSort) => {
  const handleSortAsc = () => onSort(sortCol, 'asc');
  const handleSortDesc = () => onSort(sortCol, 'desc');

  const handleTouchStartAsc = (event) => {
    event.preventDefault();
    handleSortAsc();
  }

  const handleTouchStartDesc = (event) => {
    event.preventDefault(); 
    handleSortDesc();
  }

  return (
    <span className="sort-arrows">
      <span className='sort-up-arrow filter-label-focus' onClick={handleSortDesc} onTouchStart={handleTouchStartDesc} tabIndex="0" onKeyPress={(event) => { if(event.key === 'Enter') handleSortDesc(); }}></span>
      <span className='sort-bottom-arrow filter-label-focus' onClick={handleSortAsc} onTouchStart={handleTouchStartAsc} tabIndex="0" onKeyPress={(event) => { if(event.key === 'Enter') handleSortAsc(); }}></span>
    </span>
  );
};
