import React from 'react'
import { map, concat, includes } from 'lodash'

import IconInfoImage from "images/icon-info.png"

import GenSelectList from 'Gen/SelectList/component'
import GenButton from 'Gen/Button/component'
import GenPostButton from 'Gen/PostButton/component'
import GenModal from 'Gen/Modal/component'
import GroupRoleDefinitionUserSummary from 'Summary/component'

import {full_url} from 'helpers/url'

const TableHeader = (headers) => (
  <tr>
    <th className='checkbox' style={{width: "25px"}} />
    { headers.map((header) => (
      <th key={header[0]} className={header[0]}>
        {header[1]}
      </th>
    ))}
  </tr>
)

export default class GroupRoleDefinitionUserSelectList extends React.Component {
  state = {
    selectedGroupRoleDefinitionUserIds : [],
    destroyProcessing: false,
    setExpirationProcessing: false,
    key: 1,
    setExpirationModalOpen: false,
    setExpirationOption: 'past',
    setExpirationDay: '',
    setExpirationMonth: '',
    setExpirationYear: '',
  };

  handleGroupRoleDefinitionUserSelectionChange = (newSelection) => {
    this.setState({selectedGroupRoleDefinitionUserIds : newSelection});
  };

  handleDestroySelectedGroupRoleDefinitions = (message) => {
    if (this.state.selectedGroupRoleDefinitionUserIds.length === 0) {
      alert("Nothing Selected! You must select at least one item.");
      return(null);
    }

    window.customConfirmBox({ message, callback: this.destroySelectedGroupRoleDefinitions })
  };

  destroySelectedGroupRoleDefinitions = () => {
    let data = {groupRoleDefinitionUserIds: this.state.selectedGroupRoleDefinitionUserIds};

    let url = new URL(window.location.href);

    if (this.props.only_students) {
      url.pathname += "/confirm_batch_destruction";
    } else {
      url.pathname += "/remove_admin_batch";
    }
    let destroy_url = url.href;
    const params = new URLSearchParams(url.search);
    params.append('groupRoleDefinitionUserIds', JSON.stringify(this.state.selectedGroupRoleDefinitionUserIds));

    this.setState({destroyProcessing: true});

    // The following is adapted from https://stackoverflow.com/a/38445519/1976637,
    // as a way to redirect with a POST request.
    // We need to use a POST request because the list of GRDU ids can exceed
    // the limit for a GET request.
    var form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = destroy_url;
    for (var name in data) {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'groupRoleDefinitionUserIds';
      input.value = JSON.stringify(this.state.selectedGroupRoleDefinitionUserIds);
      form.appendChild(input);
    }
    form.submit();
  };

  handleToggleSetExpirationModalOpen = (e) => {
    this.setState({
      setExpirationModalOpen: !this.state.setExpirationModalOpen
    });
  };

  handleSetExpirationOptionChange = (e) => {
    this.setState({
      setExpirationOption: e.target.value
    });
  };

  handleSetExpirationDay = (e) => {
    this.setState({
      setExpirationDay: e.target.value
    });
  };

  handleSetExpirationMonth = (e) => {
    this.setState({
      setExpirationMonth: e.target.value
    });
  };

  handleSetExpirationYear = (e) => {
    this.setState({
      setExpirationYear: e.target.value
    });
  };

  intDate = () => {
    if (this.state.setExpirationMonth > 12 || this.state.setExpirationDay > 31 || this.state.setExpirationYear > 99) {
      return(NaN);
    } else {
      var date = Date.parse(this.state.setExpirationMonth + "/" + this.state.setExpirationDay + "/" + this.state.setExpirationYear);
      if (date > Date.now()) {
        return(date);
      } else {
        return(NaN);
      }
    }
  };

  validDate = () => {
    return(!isNaN(this.intDate()));
  };

  onDestroySelectedGroupRoleDefinitionsSuccess = () => {
    this.setState({
      selectedGroupRoleDefinitionUserIds : [],
      key: this.state.key + 1,
      destroyProcessing: false
    });
    alert("Users Removed!");
  };

  handleSetExpirationSubmit = (e) => {
    if (this.state.selectedGroupRoleDefinitionUserIds.length === 0) {
      alert("Nothing Selected! You must select at least one item to lend.");
      return(null);
    }

    let data = {
      group_role_definition_user_ids: this.state.selectedGroupRoleDefinitionUserIds,
      expiration_mode: this.state.setExpirationOption,
    };

    if (this.state.setExpirationOption === "date") {
      data.expiration_date = this.intDate();
    }

    let url = new URL(window.location.href);
    url.pathname += "/update_expiration_batch";
    let expiration_url = url.href;

    this.setState({
      setExpirationProcessing: true,
      setExpirationModalOpen: false
    });

    let outsideThis = this;
    $.ajax({
      type: "PATCH",
      url: expiration_url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onSetExpirationSubmitSuccess,
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        outsideThis.setState({destroyProcessing: false});
        alert("Error: Updating Users!");
      }
    });
  };

  onSetExpirationSubmitSuccess = () => {
    this.setState({
      selectedGroupRoleDefinitionUserIds : [],
      key: this.state.key + 1,
      setExpirationProcessing: false
    });
    alert("Users Updated!");
  };

  render() {
    var invalidDateMessage = null;
    if (!this.validDate() && this.state.setExpirationMonth.length > 0 && this.state.setExpirationDay.length > 0 && this.state.setExpirationYear.length > 0) {
      invalidDateMessage = <p>Invalid Date: Date must be valid and in the future</p>;
    }
    var selectExtraButtons = []
    if (this.props.can_destroy_batch) {
      selectExtraButtons.unshift(
        <GenButton
          key="RemoveUsers"
          name="Remove Selected Members"
          highlighted={true}
          loading={this.state.destroyProcessing}
          small={true}
          disabled={this.state.selectedGroupRoleDefinitionUserIds.length === 0}
          onClick={() => this.handleDestroySelectedGroupRoleDefinitions(this.props.grduDeleteWarning)}
        />
      );
    }

    selectExtraButtons.unshift(<GenPostButton
      key="SummaryCSV"
      name="Export User List"
      highlighted={true}
      small={true}
      disabled={this.state.selectedGroupRoleDefinitionUserIds.length === 0}
      url={full_url(window.location.pathname + "/program_user_summary_csv.csv")}
      data={{ids: this.state.selectedGroupRoleDefinitionUserIds}}
    />
    );

    selectExtraButtons.unshift(<GenButton
      key="ResendInvite"
      name="Send Email Invitation"
      highlighted={true}
      small={true}
      disabled={this.state.selectedGroupRoleDefinitionUserIds.length === 0}
      onClick={() => {
          let result = confirm("Invite emails will only be sent to users with Registration Pending status. Send email invitation to register now?");
          if (result == true) {
            window.location.href = full_url(window.location.pathname + "/resend_invite_email?" + this.state.selectedGroupRoleDefinitionUserIds.map((id) => {return "ids%5B%5D=" + id}).join("&"))
          } else {
          }
        }
      }
    />
    );

    if (this.props.is_admin) {
      selectExtraButtons.unshift(<GenButton key="SetExpiration" name="Set Expiration For Selected" highlighted={true} loading={this.state.setExpirationProcessing} small={true} disabled={this.state.selectedGroupRoleDefinitionUserIds.length === 0} onClick={this.handleToggleSetExpirationModalOpen}/>);
    }

    let permanentFilters = this.props.permanentFilters;
    let selectedGRDUIds = this.state.selectedGroupRoleDefinitionUserIds;

    var emailWidth = {
      maxWidth: '0px',
      minWidth: '260px',
      overflow: 'hidden'
    };
    var roleWidth = {
      maxWidth: '200px',
      minWidth: '0px'
    };
    let variableColumn;
    let lcaCourseColumn;
    let subscriptionExpiration;
    let filters = [
      {
        "title": "Filter by Status",
        "options": {
          "Invited": ["is_null", "group_role_definition_users.subscription_end"],
          "Registered": [">", "group_role_definition_users.subscription_end", (new Date('01 January 2000 00:00 UTC').toISOString())]
        }
      }
    ];

    let sortOptions = [
      {
        'title': 'Date Added',
        'value': 'group_role_definition_users.created_at'
      },
      {
        'title': 'First Name',
        'value': 'first_name'
      },
      {
        'title': 'Last Name',
        'value': 'last_name'
      },
      {
        'title': 'Email',
        'value': 'email'
      },
      {
        'title': 'Role',
        'value': 'role_definitions.name'
      },
      {
        'title': 'Subscription Expiration ',
        'value': 'group_role_definition_users.subscription_end'
      }
    ];

    let roleOptions = {
      'Program Service Administrator': ['=', 'role_definitions.name', 'Program Service Administrator'],
      'Curriculum Administrator': ['=', 'role_definitions.name', 'Curriculum Administrator'],
      'Lead Course Administrator': ['=', 'role_definitions.name', 'Lead Course Administrator'],
      'Custom Course Manager': ['=', 'role_definitions.name', 'Custom Course Manager'],
      'Teacher': ['=', 'role_definitions.name', 'Teacher'],
      'IT Administrator': ['=', 'role_definitions.name', 'IT Administrator'],
      'Student Roster Manager': ['=', 'role_definitions.name', 'Student Roster Manager'],
    }

    let lcaCourseOptions = {
      'Aquifer Family Medicine': ['=', 'document_sets.name', 'Aquifer Family Medicine'],
      'Aquifer Geriatrics': ['=', 'document_sets.name', 'Aquifer Geriatrics'],
      'Aquifer Internal Medicine': ['=', 'document_sets.name', 'Aquifer Internal Medicine'],
      'Aquifer Neurology': ['=', 'document_sets.name', 'Aquifer Neurology'],
      'Aquifer Obstetrics and Gynecology': ['=', 'document_sets.name', 'Aquifer Obstetrics and Gynecology'],
      'Aquifer Pediatrics': ['=', 'document_sets.name', 'Aquifer Pediatrics'],
      'Aquifer Radiology': ['=', 'document_sets.name', 'Aquifer Radiology'],
      'Aquifer Sciences Integrated Illness Scripts': ['=', 'document_sets.name', 'Aquifer Sciences Integrated Illness Scripts'],
      'Aquifer Palliative Care': ['=', 'document_sets.name', 'Aquifer Palliative Care'],
      'Aquifer Trauma-Informed Care': ['=', 'document_sets.name', 'Aquifer Trauma-Informed Care'],
      'Aquifer Diagnostic Excellence': ['=', 'document_sets.name', 'Aquifer Diagnostic Excellence'],
      'Aquifer Telemedicine': ['=', 'document_sets.name', 'Aquifer Telemedicine'],
      'Aquifer Social Determinants of Health': ['=', 'document_sets.name', 'Aquifer Social Determinants of Health'],
      'Aquifer High Value Care': ['=', 'document_sets.name', 'Aquifer High Value Care']
    };

    let editorialRoleOptions = {
      'Combined Production Associate': ['=', 'role_definitions.name', 'Combined Production Associate'],
      'Content Production Associate': ['=', 'role_definitions.name', 'Content Production Associate'],
      'Assessment Production Associate': ['=', 'role_definitions.name', 'Assessment Production Associate'],
    }

    if(this.props.is_admin && this.props.is_editorial_group) {
      roleOptions = {...roleOptions, ...editorialRoleOptions}
    }

    switch(this.props.permanentFilters[0]) {
      case "!=":
        lcaCourseColumn = 'LCA Course';
        filters.unshift({
            'title': 'Filter by Role',
            'options': roleOptions
          },
          {
            'title': 'Filter LCA by Course',
            'options': lcaCourseOptions
          }
        );
      case "in":
        variableColumn = 'Clinical Discipline';
        sortOptions.push(
          {
            "title": "Clinical Discipline",
            "value": "group_role_definition_users.primary_clinical_discipline"
          }
        );
        break;
      case "=":
        variableColumn = <div>Anticipated Graduation <img src={IconInfoImage} alt="case name" className="icon-case_name" title="These are student entered data." /></div>;
        sortOptions.push(
          {
            "title": "Anticipated Graduation",
            "value": "group_role_definition_users.graduation_day"
          }
        );

        break;
      default:
        variableColumn = '';
        lcaCourseColumn = '';
    }

    let displayLcaCourseColumn = lcaCourseColumn ? [['lca-course', lcaCourseColumn]] : [];

    let tableHeader = TableHeader([
      ['first-name', "First Name"],
      ['last-name', "Last Name"],
      ['email', "Email"],
      ['role', "Role"],
      ...displayLcaCourseColumn,
      ['variableColumn', variableColumn],
      ['status', "Status"],
      ['date-added', "Date Added"],
      ['subscription-expiration', "Subscription Expiration"],
      ['actions', ''],
    ]);

    return(
      <div>
      <GenModal open={this.state.setExpirationModalOpen} closeModelFunction={this.handleToggleSetExpirationModalOpen}>
        <div className="gen-modal-panel">
          <h1>Set Expiration</h1>
          <div>
            <form action="">
              <div>
                <label><input type="radio" value="past" checked={this.state.setExpirationOption === "past"} onChange={this.handleSetExpirationOptionChange}/>Expire Now</label>
              </div>
              <div>
                <label><input type="radio" value="never" checked={this.state.setExpirationOption === "never"} onChange={this.handleSetExpirationOptionChange}/>Never Expire</label>
              </div>
              <div>
                <label><input type="radio" value="date" checked={this.state.setExpirationOption === "date"} onChange={this.handleSetExpirationOptionChange}/>Expire On Date...</label>
              </div>
              <div>
                <input type="text" name="month" placeholder="mm"
                       disabled={"date" != this.state.setExpirationOption}
                       style={{width: 45 + 'px', display: 'inline-block'}}
                       value={this.state.setExpirationMonth}
                       onChange={this.handleSetExpirationMonth}/>/
                <input type="text" name="day" placeholder="dd"
                       disabled={"date" != this.state.setExpirationOption}
                       style={{width: 45 + 'px', display: 'inline-block'}}
                       value={this.state.setExpirationDay}
                       onChange={this.handleSetExpirationDay}/>/
                <input type="text" name="year" placeholder="yy"
                       disabled={"date" != this.state.setExpirationOption}
                       style={{width: 45 + 'px', display: 'inline-block'}}
                       value={this.state.setExpirationYear}
                       onChange={this.handleSetExpirationYear}/>
                {invalidDateMessage}
              </div>
            </form>
            <div className="gen-button-left-group">
              <GenButton name="Set Expiration" highlighted={true} loading={this.state.destroyProcessing} small={true} disabled={(this.state.setExpirationOption === "date") && !this.validDate()} onClick={this.handleSetExpirationSubmit}/>
              <GenButton name="Cancel" highlighted={true} small={true} onClick={this.handleToggleSetExpirationModalOpen}/>
            </div>
          </div>
        </div>
      </GenModal>
      <GenSelectList
        key={this.state.key}
        selected={this.state.selectedGroupRoleDefinitionUserIds}
        onSelectionChange={this.handleGroupRoleDefinitionUserSelectionChange}
        selectExtraButtons={selectExtraButtons}
        permanentFilters={this.props.permanentFilters}
        itemsURL="/group_role_definition_users"
        isInfiniteScroll
        search
        defaultItemsPerPage={64}
        sortOptions={sortOptions}
        genericFilterOptions={filters}
        tableHeader={tableHeader}
        itemView={(grdu) => (
          <GroupRoleDefinitionUserSummary
            groupRoleDefinitionUser={grdu}
            projectNames={grdu.project_names}
            key={`grdu-${grdu.id}`}
            selected={includes(selectedGRDUIds, grdu.id)}
            permanentFilters={this.props.permanentFilters}
            onClick={ grdu.onClick }
          />
        )}
      />

      </div>
    );
  }
}
