import * as React from 'react';
import { useEffect, useState } from "react";
import MySwal from "../../../../../ui/MySwal";
import { importAdminAssessmentQuestions } from "../../../../../api/sdk.gen";

const AdminAssessmentQuestionsImport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleImportButtonClick = () => {
    MySwal.fire({
      title: "Import New Questions",
      html: `
        <div style="text-align: left;">
          <p>
            Before proceeding, please confirm the import file is in
            <a href="https://docs.google.com/spreadsheets/d/1VE11quZWfNmP4C4GOn8yVBkdVhEm-7pj8DzKlBd9Uqg/edit#gid=0" target="_blank">
              this format
            </a>.
          </p>
          <p>
            Please do not use a previously used file. It will create duplicate questions.
          </p>
          <input id="fileInput" type="file" accept=".xlsx,.csv" style="margin-top: 10px;" />
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: "Submit",
      preConfirm: () => {
        const fileInput = document.getElementById("fileInput") as HTMLInputElement;
        if (!fileInput || !fileInput.files?.length) {
          MySwal.showValidationMessage("Please select a file to upload.");
          return false;
        }
        return fileInput.files[0]; // Return the selected file
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const file = result.value; // File returned from preConfirm

        if (file) {
          setIsLoading(true); // Show loading overlay

          try {
            const resp = await importAdminAssessmentQuestions({
              body: {
                csv_file: file,
              },
            });
            MySwal.fire(
              "Import finished!",
              resp.data.message,
              resp.data.success ? "success" : "error"
            );
          } catch (error) {
            MySwal.fire("Import failed", "An error occurred while importing the file.", "error");
          } finally {
            setIsLoading(false); // Hide loading overlay
          }
        }
      }
    });
  };

  return (
    <>
      <button onClick={handleImportButtonClick}>{isLoading ? 'Importing...' : 'Import New Questions'}</button>

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Importing...
        </div>
      )}
    </>
  );
};

export default AdminAssessmentQuestionsImport;
